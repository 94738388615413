


































import Api from "@/config/api";
import { StatistikType } from "@/data/StatistikType";
import axios from 'axios';

export default {
  data: () => ({}),
  props: {
    uebungsset: Object,
    uebung: Object,
  },
  mounted() {

  },
  methods: {
    playerStarted() {
      axios
        .post(
          Api.getRequestConfig().baseURL +
          "/statistik"
          ,
          { type: StatistikType.VIDEOAUFRUF, aktionid: this.uebung.videoUrl.split("/")[3] },
          {
            headers: Api.getRequestConfig().headers,
          }
        )
    }
  },
  computed: {
    url() {
      return (
        "https://player.vimeo.com/video/" + this.uebung.videoUrl.split("/")[3]
      );
    },
    videoId() {
      return this.uebung.videoUrl.split("/")[3]
    }
  },
};
