



















































































































































































import AboNoticesComponent from "@/components/abo/patient/AboNoticesComponent.vue";
import Api from "@/config/api";
import { AboBuchungDetailedStatus } from "@/data/abo/AboBuchungDetailedStatus";
import AboBuchungDto from "@/dtos/AboBuchungDto";
import AboApi from "@/services/api/Abo";
import DokumentApi from "@/services/api/Dokument";
import axios from "axios";

export default {
  data() {
    return {
      cancelDialog: false,
      reactivateDialog: false,
      bezahlungslink: null,
    };
  },
  components: {
    AboNoticesComponent,
  },
  props: {
    abo: {
      type: Object,
      default: null,
    },
  },
  created: function () {
    if (this.abo.status == "INBEZAHLUNG") {
      AboApi.ladePaymentUrl().then((data) => {
        this.bezahlungslink = data;
      });
    }
  },
  methods: {
    getAboDetailedStatus(status: AboBuchungDetailedStatus) {
      if (status === AboBuchungDetailedStatus.INBUCHUNG) {
        return "Im Buchungsprozess";
      } else if (status === AboBuchungDetailedStatus.INBEZAHLUNG) {
        return "Im Bezahlvorgang";
      } else if (status === AboBuchungDetailedStatus.AKTIV) {
        return "Aktiv";
      } else if (status === AboBuchungDetailedStatus.WARTE_AUF_ABOZAHLUNG) {
        return "Abo-Zahlung erwartet";
      } else {
        return "Unbekannt";
      }
    },
    getAboBuchungsdatum(abo: AboBuchungDto) {
      const buchungsdatum = new Date(abo.buchungsdatum);
      const dateString =
        buchungsdatum.getDate() +
        "." +
        (buchungsdatum.getMonth() + 1) +
        "." +
        buchungsdatum.getFullYear();
      return dateString;
    },
    getSpaetestesKuendigungsdatum(abo: AboBuchungDto) {
      const letztmoeglichesKuendigungsDatum = new Date(
        abo.letztmoeglicherKuendigungsTag
      );
      const dateString =
        letztmoeglichesKuendigungsDatum.getDate() +
        "." +
        (letztmoeglichesKuendigungsDatum.getMonth() + 1) +
        "." +
        letztmoeglichesKuendigungsDatum.getFullYear();
      return dateString;
    },
    formatDate(dateString: string) {
      const date = new Date(dateString);
      return (
        date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
      );
    },
    getAboKosten(abo: AboBuchungDto) {
      const euro = abo.aboPaket.kostenProQuartalCent / 100;
      const cent = abo.aboPaket.kostenProQuartalCent % 100;
      const centString = cent < 10 ? "0" + cent : cent;
      return (
        euro +
        "," +
        centString +
        " € / " +
        abo.aboPaket.buchungMonate +
        " Monate"
      );
    },
    isAboUngekuendigtUndUnwiderrufen(abo: AboBuchungDto) {
      return abo.vertragsendeDatum == null || abo.vertragsendeDatum == "";
    },
    aboBeenden() {
      this.cancelDialog = true;
    },
    aboBeendenConfirmed() {
      this.cancelDialog = false;

      const abo = this.abo;
      const url = "/abo/patient/cancel/" + abo.id;
      const router = this.$router;
      axios
        .get(url, Api.getRequestConfig())
        .then((response) => {
          const date = new Date(response.data);
          const dateString =
            date.getDate() +
            "." +
            (date.getMonth() + 1) +
            "." +
            date.getFullYear();
          this.$store.state.alert = {
            isVisible: true,
            headline: "Abo gekündigt",
            text: "Das Abo wurde zum " + dateString + " gekündigt.",
            okAction: function () {
              router.go();
            },
          };
        })
        .catch((errorResponse) => {
          
          const status = errorResponse.response.status;
          if (status == 404 || status == 400 || status == 500) {
            this.$store.state.error = {
              isVisible: true,
              headline: "Fehler beim Kündigen des Abos",
              text: errorResponse.response.data,
              okAction: function () {
                this.$store.state.error.isVisible = false;
              },
            };
          }
        });
    },
    getAboVertragsendeDatum(abo: AboBuchungDto) {
      return this.formatDate(abo.vertragsendeDatum);
    },
    getAboEndeGrund(abo: AboBuchungDto) {
      return abo.kuendigungVorgemerkt
        ? "Gekündigt"
        : abo.kuendigungBetreiberVorgemerkt
        ? "Gekündigt durch DiHS"
        : abo.widerrufen
        ? "Widerrufen"
        : "Unbekannt";
    },
    aboKuendigungsBestatigungLaden() {
      DokumentApi.getAboKuendigungsBestaetigung();
    },
    aboBestatigungLaden() {
      DokumentApi.getAboBestaetigung();
    },
    istFirmenAbo(abo: AboBuchungDto) {
      return !abo.bezahlabo;
    },
    kuendigungWiderrufen() {
      this.reactivateDialog = true;
    },
    reactivateAboConfirmed() {
      this.reactivateDialog = false;

      const abo = this.abo;
      const url = "/abo/patient/reactivate/" + abo.id;
      const router = this.$router;
      axios
        .get(url, Api.getRequestConfig())
        .then(() => {
          this.$store.state.alert = {
            isVisible: true,
            headline: "Kündigung widerrufen",
            text: "Die Kündigung wurde widerrufen.",
            okAction: function () {
              router.go();
            },
          };
        })
        .catch((errorResponse) => {
          
          const status = errorResponse.response.status;
          if (status == 404 || status == 400 || status == 500) {
            this.$store.state.error = {
              isVisible: true,
              headline: "Fehler beim Widerrufen der Kündigung",
              text: errorResponse.response.data,
              okAction: function () {
                this.$store.state.error.isVisible = false;
              },
            };
          }
        });
    },
    bezahlungAbschliessen() {
      if (this.bezahlungslink != null && this.bezahlungslink != "") {
        window.location.replace(this.bezahlungslink);
      }
    },
  },
};
