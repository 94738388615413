import jwtDecode from "jwt-decode";
import SecureLS from "secure-ls";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { Privilege } from "./data/user/Privilege";
import { Role } from "./data/user/Role";
const ls = new SecureLS({ encodingType: 'aes' });

Vue.use(Vuex);

export interface Authentication {
  token: string | undefined;
  email: string | undefined;
  role: Role | undefined;
  privileges: Privilege[] | undefined;
  userId: number | undefined;
  expiration: number | undefined;
}

export interface ErrorMessage {
  isVisible: boolean;
  headline: string;
  text: string;
  okAction: any;
}

export interface AlertMessage {
  isVisible: boolean;
  headline: string;
  text: string;
  okAction: any;
}

export interface VerbindungsTest {
  isDone: boolean;
  isFailed: boolean;
}

export interface Store {
  authentication: Authentication;
  error: ErrorMessage;
  menueChanged: boolean;
  selectedTermine: Array<any>;
  selectedPatientId: number;
  verbindungsTest: VerbindungsTest;
  alert: AlertMessage;
}

interface JwtToken {
  sub: string;
  role: Role;
  privileges: Privilege[];
  userId: number;
  exp: number;
}

export const store = new Vuex.Store<Store>({
  //plugins: [createPersistedState({ storage: window.localStorage })],
  plugins: [createPersistedState({
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    }
  })],
  state: {
    authentication: {
      token: undefined,
      email: undefined,
      role: undefined,
      privileges: undefined,
      userId: undefined,
      expiration: undefined,
    },
    error: {
      isVisible: false,
      headline: "",
      text: "",
      okAction: function () { },
    },
    menueChanged: false,
    selectedTermine: [],
    selectedPatientId: 0,
    verbindungsTest: {
      isDone: false,
      isFailed: false
    },
    alert: {
      isVisible: false,
      headline: "",
      text: "",
      okAction: function () { },
    },
  },
  getters: {
    loggedIn: state => {
      return state.authentication.token !== undefined;
    },
    authentication: state => {
      return state.authentication;
    },
    isMe: (state, getters) => (userId: number) => {
      return getters.loggedIn && state.authentication.userId === userId;
    },
    isRole: (state, getters) => (role: Role) => {
      return getters.loggedIn && state.authentication.role === role;
    },
    hasPrivilege: (state, getters) => (privilege: Privilege) => {
      const privileges: Privilege[] | undefined =
        state.authentication.privileges;
      return (
        getters.loggedIn &&
        privileges !== undefined &&
        privileges.indexOf(privilege) !== -1
      );
    },
    verbindungsTest: state => {
      return state.verbindungsTest;
    }
  },
  mutations: {
    login(state, token) {
      state.authentication.token = token;
      const decodedToken: JwtToken = jwtDecode<JwtToken>(token);
      state.authentication.email = decodedToken.sub;
      state.authentication.role = decodedToken.role;
      state.authentication.privileges = decodedToken.privileges;
      state.authentication.userId = decodedToken.userId;
      state.authentication.expiration = decodedToken.exp;
    },
    logout(state) {
      state.authentication.token = undefined;
      state.authentication.email = undefined;
      state.authentication.role = undefined;
      state.authentication.privileges = undefined;
      state.authentication.userId = undefined;
      state.authentication.expiration = undefined;
    },
    setNetworkError(state) {
      state.error = {
        isVisible: true,
        headline: "Netzwerkfehler",
        text: "Ihre Änderungen wurden nicht übernommen",
        okAction: function () { }
      };
    },
    clearError(state) {
      state.error.isVisible = false
    },
    clearAlert(state) {
      state.alert.isVisible = false
    },
    selectedTermine(state, termin) {
      if (!(state.selectedTermine.indexOf(termin) > -1)) {
        state.selectedTermine.push(termin);
      } else {
        state.selectedTermine = state.selectedTermine.filter((t: any) => {
          return t != termin;
        })
      }
    },
    urlSelectedTermine(state, termin) {
      if (!(state.selectedTermine.indexOf(termin) > -1)) {
        state.selectedTermine.push(termin);
      }
    },
    filterSelectedTermine(state, termine) {
      const removeSelection: any[] = [];
      for (const termin of state.selectedTermine) {
        if (!(termine.indexOf(termin) > -1)) {
          removeSelection.push(termin);
        }
      }
      state.selectedTermine = state.selectedTermine.filter(termin => {
        return !removeSelection.some(removeTermin => {
          return termin === removeTermin;
        });
      });
    },
    selectedPatientId(state, id) {
      state.selectedPatientId = id;
    },
    setVerbindungsTestFailed(state, isFailed: boolean) {
      state.verbindungsTest.isDone = true;
      state.verbindungsTest.isFailed = isFailed;
    }
  },
});
