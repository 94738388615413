<template>
  <v-dialog v-model="isVisible" max-width="50%">
    <v-card>
      <v-card-title class="headline">{{
        this.$store.state.alert.headline
        }}</v-card-title>
      <v-card-text>
        {{ this.$store.state.alert.text }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dismiss">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => {
    return {
      email: "",
      password: "",
      dialog: false,
    };
  },
  computed: {
    isVisible() {
      return this.$store.state.alert.isVisible;
    },
  },
  methods: {
    dismiss() {
      this.$store.commit("clearAlert");
      if (this.$store.state.alert.okAction != null) {
        this.$store.state.alert.okAction();
      }
    },
  },
};
</script>
