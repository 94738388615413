



































































import Api from "@/config/api";
import VideoCreateDto from "@/dtos/videos/VideoCreateDto";
import Axios from "axios";

export default {
  data() {
    return {
      allFirmen: [],
      gotFirmen: false,
      editFirmen: [],
      editTitel: "",
      editKurzbeschreibung: "",
      editVimeolink: "",
      editLangtext: "",
      message: undefined,
      sending: false,
      done: false,
      createdVideo: undefined,

      titelRegeln: [
        (v: string) => !!v || "Der Titel darf nicht leer sein.",
        (v: string) => /^.{0,100}$/.test(v) || "Maximale Länge beachten",
      ],
      kurzbeschreibungRegeln: [
        (v: string) => !!v || "Die Kurzbeschreibung darf nicht leer sein.",
        (v: string) => /^.{0,200}$/.test(v) || "Maximale Länge beachten",
      ],
      vimeoRegeln: [
        (v: string) => !!v || "Der Link darf nicht leer sein.",
        (v: string) => /^.{0,200}$/.test(v) || "Maximale Länge beachten",
      ],
      langtextRegeln: [
        (v: string) => /^.{0,10000}$/.test(v) || "Maximale Länge beachten",
      ],
    };
  },
  created() {
    this.getFirmen();
    this.editFirmen = [];
    this.editTitel = "";
    this.editKurzbeschreibung = "";
    this.editVimeolink = "";
    this.editLangtext = "";
    this.message = undefined;
    this.sending = false;
    this.done = false;
    this.createdVideo = undefined;
  },
  methods: {
    getFirmen() {
      const url = "/firma/allevideo";
      if (!this.gotFirmen) {
        Axios.get(url, Api.getRequestConfig())
          .then((response) => {
            this.allFirmen = response.data;
            this.gotFirmen = true;
          })
          .catch(() => {
				    
            console.log("Error on getting firmen...");
            this.message = "Fehler beim Abruf der Firmen.";
            this.gotFirmen = false;
          });
      }
    },
    createNew() {
      const video: VideoCreateDto = new VideoCreateDto(
        this.editTitel,
        this.editKurzbeschreibung,
        this.editVimeolink,
        this.getImageUrl(this.editVimeolink),
        this.editLangtext,
        this.editFirmen
      );
      const url = "/video/betreiber/create";
      this.sending = true;
      Axios.post(url, video, Api.getRequestConfig())
        .then((response) => {
          this.message = "Erfolgreich erstellt.";
          this.done = true;
          this.createdVideo = response.data;
          this.sending = false;
        })
        .catch(() => {
				  
          this.message = "Fehler beim Erstellen...";
          this.sending = false;
        });
    },
    emitCloseMe() {
      // reset all values and emit a "close me"
      this.gotFirmen = false;
      this.editFirmen = [];
      this.editTitel = "";
      this.editKurzbeschreibung = "";
      this.editVimeolink = "";
      this.editLangtext = "";
      this.message = "";
      if (this.done && this.createdVideo != undefined) {
        this.$emit("created", this.createdVideo);
      } else {
        this.$emit("closeme");
      }
    },
    getImageUrl(link: string) {
      if (link !== undefined) {
        return "https://player.vimeo.com/video/" + link.split("/")[3];
      } else {
        return "";
      }
    },
  },
};
