import Api from "@/config/api";
import Termin from "@/data/Termin";
import axios from "axios";

export default {
	getTerminList() {
		const url: string =
			Api.getRequestConfig().baseURL + "/termin/betreiber/all";
		return axios
			.get(url, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				
				console.log(error);
				return null
				
			});
	},
	getPatientenTerminList() {
		const url: string =
			Api.getRequestConfig().baseURL + "/termin/patient/all";
		return axios.get(url, {
			headers: Api.getRequestConfig().headers,
		})
		.catch(() => {
			
			return null
		});
	},
	getPatientenTerminListForPatient(patientId: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/termin/betreiber/for/all/" + patientId;
		return axios.get(url, {
			headers: Api.getRequestConfig().headers,
		})
		.catch(() => {
			
			return null
		});
	},
	getMyTerminList() {
		const url: string =
			Api.getRequestConfig().baseURL + "/termin/patient/angemeldet";
		return axios.get<[Termin]>(url, {
			headers: Api.getRequestConfig().headers,
		})
		.catch(() => {
			
			return null
		});
	},
	getTherapeutTerminListByStatus(status: string) {
		const url: string =
			Api.getRequestConfig().baseURL + "/termin/therapeut/all/" + status;
		return axios.get<[Termin]>(url, {
			headers: Api.getRequestConfig().headers,
		})
		.catch(() => {
			
			return null
		});
	},
	getTermin(id: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/termin/betreiber/" + id;
		return axios.get<Termin>(url, {
			headers: Api.getRequestConfig().headers,
		})
		.catch(() => {
			
			return null
		});
	},
	storeTermin(termin: Termin) {
		const url: string =
			Api.getRequestConfig().baseURL + "/termin/betreiber/save";
		return axios
			.post(url, termin, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				
				console.log(error);
				return null
				
			});
	},
	changeTeilgenommen(terminAnmeldung: any, terminId: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/terminanmeldung/patient/teilgenommen/" + terminId;
		return axios
			.post(url, terminAnmeldung, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				
				console.log(error);
				return null
				
			});
	},
	terminAktivieren(terminId: number) {
		const url: string =
			Api.getRequestConfig().baseURL +
			"/termin/betreiber/aktivieren/" +
			terminId;
		return axios.get(url, { headers: Api.getRequestConfig().headers })
		.catch(() => {
			
			return null
		});
	},
	terminAbsagen(terminId: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/termin/betreiber/absagen/" + terminId;
		return axios.get(url, { headers: Api.getRequestConfig().headers })
		.catch(() => {
			
			return null
		});
	},
	anmelden(terminId: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/termin/patient/anmelden/" + terminId;
		return axios.get(url, { headers: Api.getRequestConfig().headers })
		.catch(() => {
			
			return null
		});
	},
	abmelden(terminId: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/termin/patient/abmelden/" + terminId;
		return axios.get(url, { headers: Api.getRequestConfig().headers })
		.catch(() => {
			
			return null
		});
	},
	getTherapeut(terminId: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/termin/patient/therapeut/byconference/" + terminId;
		return axios.get(url, { headers: Api.getRequestConfig().headers })
		.catch(() => {
			
			return null
		});
	},
};
