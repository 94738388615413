import Api from "@/config/api";
import AuthObject from "@/data/AuthObject";
import axios from "axios";

export default {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	retrieveSessionToken: (context: any, email: string, password: string, secondFactor: string) => {
		const authObject: AuthObject = {
			email: email,
			password: password,
			secondFactorCode: secondFactor,
		};
		axios
			.post("/authenticate", authObject, Api.getRequestConfig())
			.then(function (response) {
				context.$store.commit("login", response.data.token);
			})
			.catch(function (error) {
				context.$store.state.error = {
					isVisible: true,
					headline: "Fehler bei der Anmeldung",
					text: error.response.data.message,
				};
				console.log(error);
			});
	},
	checkSecondFactor: (context: any, email: string, password: string) => {
		const authObject: AuthObject = {
			email: email,
			password: password,
			secondFactorCode: "",
		};
		return axios
			.post("/authenticate/secondFactorRequired", authObject, Api.getRequestConfig())
			.catch(function () {
				
				return null
			});
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	logout: (context: any) => {
		context.$store.commit("logout");
		// RedmineTicket: Feature #4240 auch am Backend ausloggen
	},
};
