






































import axios from "axios";
import { mapMutations } from "vuex";
import Api from "../../../config/api";
export default {
  /*
   * wiederverwendbarkeit ist gegeben? (beispielsweise uploadpfad und "multiple" attribute)
   */
  data() {
    return {
      files: [],
    };
  },
  props: {
    destination: {
      type: String,
      default: "/upload",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    ownButton: {
      type: Boolean,
      default: undefined,
    },
    encrypt: {
      type: Boolean,
      default: false,
    },
    extraDescription: {
      type: String,
      default: null,
    },
  },
  computed: {
    allUploaded: function () {
      let response = true;
      for (let file of this.files) {
        if (!file.uploaded) {
          response = false;
        }
      }
      return response;
    },
    ...mapMutations(["setNetworkError"]),
  },
  watch: {
    ownButton() {
      if (this.ownButton) {
        this.submitFiles();
      }
    },
  },
  methods: {
    submitFiles() {
      const config = Api.getRequestConfig();
      config.headers["Content-Type"] = "multipart/form-data";
      for (var i = 0; i < this.files.length; i++) {
        let formData = new FormData();
        const file = this.files[i];
        const that = this;
        formData.append("file", file.file);
        if (this.extraDescription != null) {
          formData.append("description", this.extraDescription);
        } else {
          formData.append("description", file.description);
        }
        formData.append("encrypt", this.encrypt);
        if (!file.uploaded) {
          axios
            .post(this.destination, formData, config)
            .then((response) => {
              this.$emit("uploaded");
              if (!this.multiple) {
                window.history.go();
              } else {
                file.uploaded = true;
                if (this.allUploaded) {
                  window.history.go();
                }
              }
            })
            .catch((error: Error) => {
              
              console.log(error);
              this.$store.commit("setNetworkError");
            });
        }
      }
    },
    handleFilesUpload() {
      this.files = [];
      for (const file of this.$refs.files.files) {
        this.files.push({ file: file, description: "", uploaded: false });
      }
    },
    removeFiles() {
      if (this.files.value != null) {
        this.files.value = null;
        this.files.files = null;
      }
    },
  },
};
