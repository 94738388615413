import Api from "@/config/api";
import Therapeut from "@/data/Therapeut";
import axios from "axios";

export default {
  getAllTherapeuten() {
    const url: string =
      Api.getRequestConfig().baseURL + "/therapeut/betreiber/all";
    return axios.get<[Therapeut]>(url, {
      headers: Api.getRequestConfig().headers,
    })
		.catch(() => {
			
      return null
		});
  },
  getAllTherapeutenForPatient() {
    const url: string =
      Api.getRequestConfig().baseURL + "/therapeut/patient/all";
    return axios.get<[Therapeut]>(url, {
      headers: Api.getRequestConfig().headers,
    })
		.catch(() => {
			
      return null
		});
  },
};
