











































import ProfilBenutzerDto from "@/data/ProfilBenutzerDto";
import Axios from "axios";
import { mapMutations } from "vuex";
import api from "../../config/api";
export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    editName: false,
    dialogEditName: false,
    vornameModel: null,
    nachnameModel: null,
  }),
  created() {
    this.vornameModel = this.info.vorname;
    this.nachnameModel = this.info.nachname;
  },
  computed: {
    ...mapMutations(["setNetworkError"])
  },
  methods: {
    changeName() {
      if (!this.editName) {
        this.editName = true;
      } else {
        this.dialogEditName = true;
      }
    },
    editNameSave() {
      this.info.vorname = this.vornameModel;
      this.info.nachname = this.nachnameModel;
      this.dialogEditName = false;
      this.editName = false;

      const profil: ProfilBenutzerDto = new ProfilBenutzerDto(
        this.info.vorname,
        this.info.nachname,
        "",
        "",
        this.info.hausnummer,
        this.info.straße,
        this.info.plz,
        this.info.ort,
        this.info.land,
        this.info.profilbild,
        this.info.ausbildung,
        this.info.schwerpunkte,
        this.info.beschreibung,
        this.info.geburtsdatum,
        this.info.telefon,
        this.info.listenanzeige
      );
      let requestUrl = "/profil/change";
      if (this.user !== null) {
        requestUrl = "/profil/other/" + this.user.id + "/betreiber";
      }
      Axios.post(requestUrl, profil, api.getRequestConfig())
      .catch((error: Error) => {
        
        this.$store.commit("setNetworkError");
      })
    },
    editNameDiscard() {
      this.vornameModel = this.info.vorname;
      this.nachnameModel = this.info.nachname;
      this.dialogEditName = false;
      this.editName = false;
    },
    editNameBack() {
      this.dialogEditName = false;
    },
  }
}
