



































































































































































































































































































































import Api from "@/config/api";
import { Privilege } from "@/data/user/Privilege";
import GuthabenDto from "@/dtos/GuthabenDto";
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      guthaben: null,
      editGuthaben: false,
      editRezeptGuthaben: false,
      editAboGuthaben: false,
      editGuthabenEinzel: 0,
      editGuthabenGruppe: 0,
      editGuthabenBefund: 0,
      editGuthabenTelefon: 0,
      editGuthabenAnamnese: 0,
      editRezeptGuthabenEinzel: 0,
      editRezeptGuthabenGruppe: 0,
      editRezeptGuthabenBefund: 0,
      editAboGuthabenEinzel: 0,
      editAboGuthabenGruppe: 0,
      editAboGuthabenBefund: 0,
      editAboGuthabenTrainingsplan: 0,
    };
  },
  props: {
    patientId: {
      type: Number,
      default: null,
    },
  },
  created: function () {
    this.getGuthaben();
  },
  methods: {
    getGuthaben: function () {
      const url = "/guthaben/betreiber/get/" + this.patientId;
      return axios
        .get<GuthabenDto>(url, Api.getRequestConfig())
        .then((response) => {
          this.guthaben = response.data;
        })
        .catch((error) => {
          
          console.log(error);
        });
    },
    startEditGuthaben() {
      this.editGuthaben = true;
      this.editGuthabenEinzel = this.guthaben.bezahltEinzelTermin;
      this.editGuthabenGruppe = this.guthaben.bezahltGruppenTermin;
      this.editGuthabenTelefon = this.guthaben.bezahltTelefonTermin;
      this.editGuthabenBefund = this.guthaben.bezahltBefundTermin;
      this.editGuthabenAnamnese = this.guthaben.bezahltAnamneseTermin;
    },
    startEditRezeptGuthaben() {
      this.editRezeptGuthaben = true;
      this.editRezeptGuthabenEinzel = this.guthaben.rezeptEinzelTermin;
      this.editRezeptGuthabenGruppe = this.guthaben.rezeptGruppenTermin;
      this.editRezeptGuthabenBefund = this.guthaben.rezeptBefundTermin;
    },
    startEditAboGuthaben() {
      this.editAboGuthaben = true;
      this.editAboGuthabenEinzel = this.guthaben.aboEinzelTermin;
      this.editAboGuthabenGruppe = this.guthaben.aboGruppenTermin;
      this.editAboGuthabenBefund = this.guthaben.aboBefundTermin;
      this.editAboGuthabenTrainingsplan = this.guthaben.aboTrainingsplan;
    },
    cancelEditGuthaben() {
      this.editGuthaben = false;
    },
    cancelEditRezeptGuthaben() {
      this.editRezeptGuthaben = false;
    },
    cancelEditAboGuthaben() {
      this.editAboGuthaben = false;
    },
    saveEditGuthaben() {
      this.guthaben.bezahltEinzelTermin = this.editGuthabenEinzel;
      this.guthaben.bezahltGruppenTermin = this.editGuthabenGruppe;
      this.guthaben.bezahltTelefonTermin = this.editGuthabenTelefon;
      this.guthaben.bezahltBefundTermin = this.editGuthabenBefund;
      this.guthaben.bezahltAnamneseTermin = this.editGuthabenAnamnese;
      this.editGuthaben = false;
      this.storeNewGuthaben();
    },
    saveEditRezeptGuthaben() {
      this.guthaben.rezeptEinzelTermin = this.editRezeptGuthabenEinzel;
      this.guthaben.rezeptGruppenTermin = this.editRezeptGuthabenGruppe;
      this.guthaben.rezeptBefundTermin = this.editRezeptGuthabenBefund;
      this.editRezeptGuthaben = false;
      this.storeNewGuthaben();
    },
    saveEditAboGuthaben() {
      this.guthaben.aboEinzelTermin = this.editAboGuthabenEinzel;
      this.guthaben.aboGruppenTermin = this.editAboGuthabenGruppe;
      this.guthaben.aboBefundTermin = this.editAboGuthabenBefund;
      this.guthaben.aboTrainingsplan = this.editAboGuthabenTrainingsplan;
      this.editAboGuthaben = false;
      this.storeNewGuthaben();
    },
    storeNewGuthaben() {
      const url = "/guthaben/betreiber/store/" + this.patientId;
      return axios
        .post<GuthabenDto>(url, this.guthaben, Api.getRequestConfig())
        .then((response) => {
          this.guthaben = response.data;
        })
        .catch((error) => {
          
          this.$store.state.error = {
            isVisible: true,
            headline: "Fehler bei der Änderung",
            text: error.response.data,
            okAction: function () {},
          };
        });
    },
    canEditGuthaben() {
      return this.$store.getters.hasPrivilege(Privilege.GUTHABEN_AENDERN);
    },
  },
};
