
















































































































































































































































































































import rules from "@/config/rules";
import { Role } from "@/data/user/Role";
import ZweiFaktorInstallationsDto from "@/dtos/ZweiFaktorInstallationsDto";
import SecondFactor from "@/services/api/SecondFactor";
import Axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import api from "../../config/api";

export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    profilViewType: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      rules: rules,
      passwortAnzeigen: false,
      editMail: false,
      editPassword: false,
      dialogEditMail: false,
      dialogEditPassword: false,
      emailModel: this.user.email,
      passwordModel: "",
      passwortUeberpruefen: "",
      secondFactorActivated: false,
      dialogDeactivate2FA: false,
      dialogActivate2FA: false,
      secondFactorCurrentStep: 0,
      secondFactorToken: "",
      secondFactorActivationData: null,
      secondFactorTokenErrorMessage: "",
      dialogResendConfirmationMail: false,
      mailConfirmStatus: true,
    };
  },
  computed: {
    ...mapGetters(["isMe"]),
    ...mapMutations(["setNetworkError"]),
    passwortUeberpruefenRegel() {
      return (
        (this.passwortAnzeigen && this.passwortUeberpruefen === "") ||
        this.passwordModel === this.passwortUeberpruefen ||
        "Die Passwörter stimmen nicht überein."
      );
    },
  },
  created: function () {
    this.getSecondFactorStatus();
    if (this.user.benutzerProfil.patient) {
      this.getMailConfirmStatus();
    }
  },
  methods: {
    confirmMailSend() {
      const requestUrl = "/patient/email/erneutsenden";
      console.log("Sending Confirmation Mail to: " + this.user.email);
      Axios.post(requestUrl, this.user.email, api.getRequestConfig()).catch(
        (error: Error) => {
          
          this.$store.commit("setNetworkError");
        }
      );
      this.dialogResendConfirmationMail = false;
    },
    getMailConfirmStatus() {
      Axios.get<boolean>(
        "/patient/email/confirmed/" + this.user.email,
        api.getRequestConfig()
      )
        .then((response) => {
          if (response != null) {
            this.mailConfirmStatus = response.data;
          } else {
            console.log("MailConfirm Status Get Failed...");
          }
        })
        .catch((error) => {
          
          console.log(error);
        });
    },
    changeEditMail() {
      if (!this.editMail) {
        this.editMail = true;
      } else {
        this.dialogEditMail = true;
      }
    },
    changeEditPassword() {
      if (!this.editPassword) {
        this.editPassword = true;
      } else {
        this.dialogEditPassword = true;
      }
    },
    editMailSave() {
      this.user.email = this.emailModel;
      this.editMail = false;
      this.dialogEditMail = false;
      const requestUrl = "/user/other/" + this.user.id + "/email";
      Axios.post(requestUrl, this.user.email, api.getRequestConfig()).catch(
        (error: Error) => {
          
          this.$store.commit("setNetworkError");
        }
      );
    },
    editMailDiscard() {
      this.emailModel = this.user.email;
      this.editMail = false;
      this.dialogEditMail = false;
    },
    editMailBack() {
      this.dialogEditMail = false;
    },
    editPasswordSave() {
      this.editPassword = false;
      this.dialogEditPassword = false;
      const requestUrl = "/user/other/" + this.user.id + "/password";
      Axios.post(requestUrl, this.passwordModel, api.getRequestConfig()).catch(
        (error: Error) => {
          
          this.$store.commit("setNetworkError");
        }
      );
    },
    editPasswordDiscard() {
      this.passwordModel = "";
      this.passwortUeberpruefen = "";
      this.editPassword = false;
      this.dialogEditPassword = false;
    },
    getSecondFactorStatus() {
      SecondFactor.getStatusForUser(this.user.id)
        .then((response) => {
          this.secondFactorActivated = response?.data;
        })
        .catch((errorResponse) => {
          console.log(errorResponse);
        });
    },
    startDeactivateSecondFactor() {
      this.dialogDeactivate2FA = true;
    },
    edit2FADiscard() {
      this.dialogDeactivate2FA = false;
    },
    edit2FASave() {
      SecondFactor.deactivateForUser(this.user.id)
        .then(() => {
          this.dialogDeactivate2FA = false;
          this.getSecondFactorStatus();
        })
        .catch((errorResponse) => {
          console.log(errorResponse);
        });
    },
    isBetreiberOderTherabeutProfil(): boolean {
      return (
        Role.BETREIBER === this.user.role || Role.THERAPEUT == this.user.role
      );
    },
    startActivateSecondFactor() {
      this.resetSecondFactorData();
      this.dialogActivate2FA = true;
      this.continue2FAActivation();
    },
    continue2FAActivation() {
      if (this.secondFactorCurrentStep == 0) {
        // Validiere zunächst das Passwort und hole einen privaten Schlüssel
        SecondFactor.getActivationPrivateKeyForUser(this.user.id)
          .then((response) => {
            this.secondFactorActivationData = new ZweiFaktorInstallationsDto(
              response?.data.qrCode??"",
              response?.data.privateKey??""
            );
            this.secondFactorCurrentStep = 1;
          })
          .catch((errorResponse) => {
            console.log(errorResponse);
          });
      } else if (this.secondFactorCurrentStep == 1) {
        // Übermittle das Passwort zur erneuten Authentifizierung, den privaten Schlüssel und den eingegebenen Token,
        // um die Einrichtung abzuschließen.
        SecondFactor.activatePrivateKeyForUser(
          this.user.id,
          this.secondFactorActivationData.privateKey,
          this.secondFactorToken
        )
          .then(() => {
            this.secondFactorCurrentStep = 2;
            this.secondFactorToken = "";
            this.secondFactorPassword = "";
            this.secondFactorActivationData = null;
            this.getSecondFactorStatus();
          })
          .catch((error) => {
            if (error.response.status == 400) {
              this.secondFactorTokenErrorMessage = error.response.data;
            } else {
              if (error.response.status == 401) {
                this.secondFactorTokenErrorMessage =
                  error.response.data.message;
              } else {
                this.secondFactorTokenErrorMessage =
                  "Fehler bei der Validierung";
              }
            }
          });
      }
    },
    abord2FAActivation() {
      this.dialogActivate2FA = false;
      this.resetSecondFactorData();
    },
    resetSecondFactorData() {
      this.secondFactorCurrentStep = 0;
      this.secondFactorToken = "";
      this.secondFactorActivationData = null;
      this.secondFactorTokenErrorMessage = "";
    },
  },
};
