






































import AboNoticesComponent from "@/components/abo/patient/AboNoticesComponent.vue";
import Api from "@/config/api";
import DashboardDto from "@/data/DashboardDto";
import { Role } from "@/data/user/Role";
import axios from "axios";
import format from "date-fns/format";
import deLocale from "date-fns/locale/de";
import { mapGetters } from "vuex";
import DashboardCardComponent from "./DashboardCard.vue";
export default {
  components: {
    DashboardCardComponent,
    AboNoticesComponent,
  },
  props: {},
  data: () => ({
    iconVue: "mdi-vuetify",
    tiles: [],
    patient: Role.PATIENT,
    hatAbo: true,
  }),
  created() {
    this.setup();
  },
  methods: {
    setup() {
      this.getNow();
      setInterval(this.getNow, 1000 * 60); //makes the clock display real time
      if (this.isRole(Role.PATIENT)) {
        this.getTiles();
      }
    },
    getTiles() {
      axios
        .get(
          Api.getRequestConfig().baseURL +
            "/user/abo/" +
            this.$store.state.authentication.userId +
            "/privileges",
          {
            headers: Api.getRequestConfig().headers,
          }
        )
        .then((response) => {
          this.hatAbo = response.data;
          axios
            .get<Array<DashboardDto>>("/dashboard", Api.getRequestConfig())
            .then((response) => {
              this.tiles = response.data;
            });
        })
        .catch(() => {
          
        });
    },
    getNow: function () {
      const today = new Date();
      this.timestamp = format(today, "HH:mm", { locale: deLocale });
    },
  },
  computed: {
    ...mapGetters(["loggedIn", "isRole"]),
  },
};
