
















































import Api from "@/config/api";
import { Privilege } from '@/data/user/Privilege';
import axios from 'axios';
import { mapMutations } from 'vuex';

      export default {
    data: () => ({
        tempZeitraum: [],
        
        zeitraum: [],
        zeitraumMenu: false,

    }),
    methods: {
      getStatistik(){
        console.log(this.tempZeitraum)
        if(this.$store.getters.hasPrivilege(Privilege.STATISTIK_LESEN)){
        axios
          .get(
            Api.getRequestConfig().baseURL +
              "/statistik?from="+this.zeitraum[0]+"&to="+this.zeitraum[1] 
              ,
            {
              headers: Api.getRequestConfig().headers,
            }
          ).then(response => {

            const blob = new Blob([response.data], { type: 'text/csv' });    
            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);            
            // Create an anchor tag for downloading
            const a = document.createElement('a');            
            // Set the URL and download attribute of the anchor tag
            a.href = url;
            a.download = 'Statistik'+this.zeitraum[0]+'_'+this.zeitraum[1]+'.csv';            
            // Trigger the download by clicking the anchor tag
            a.click();
                })
            }
      },
      setZeitraum() {
        this.tempZeitraum.sort((a: string, b: string) => {
            return new Date(a) > new Date(b);
        });
        this.zeitraum = this.tempZeitraum;
        this.zeitraumMenu = false;
      },
      zeitraumAbbrechen() {
        this.zeitraumMenu = false;
        this.tempZeitraum = this.zeitraum;
      },
      formatDate(date: string) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
      
      
    },
    created() {      
    },
    computed: {
        displayedZeitraum: {
        get() {
            if (!this.zeitraum || this.zeitraum.length===0) {
            return "";
            } else {
            const dateArray: string[] = [];
            for (const date of this.zeitraum) {
                dateArray.push(this.formatDate(date));
            }
            if (dateArray.length === 1) return dateArray[0];
            else return dateArray[0] + " - " + dateArray[1];
            }
        },
        set(newZeitraum: any) {
            this.zeitraum = newZeitraum;
        },
        },
        ...mapMutations(["filterSelectedTermine"]),
    },
          
    };
  