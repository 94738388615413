


























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Api from "@/config/api";
import { Role } from "@/data/user/Role";
import { AnamneseFragebogenDto } from "@/dtos/AnamneseFragebogenDto";
import DokumentApi from "@/services/api/Dokument";
import axios from "axios";
import Vue from "vue";

interface Point {
  x: number;
  y: number;
}
interface Data {
  canvasDrawn: boolean;
  valide: boolean;
  edit: boolean;
  unterschreibbar: boolean;
  antworten: AnamneseFragebogenDto | undefined;
  patientId: number | undefined;
  nichtLeerRegel: any;
  groesserNullRegel: any;
  tagesStundenRegel: any;
  opDatumMenu: boolean;
  opDatum: string;
  angabenDatum: string;
  canvasWidthFactor: number;
  canvasHeightFactor: number;
  canvasContext: CanvasRenderingContext2D | undefined;
  canvasPoints: Point[];
  psychischBeeintraechtigt: number | null;
  schmerzStaerkeRuhe: number | null;
  schmerzStaerkeBelastung: number | null;
  patient: any | undefined;
  deleteDialog: boolean;
  deleted: boolean;
  awaitingDelete: boolean;
  viewingUserRole: Role;
  datenschutzCheckbox: boolean;
}
export default Vue.extend({
  data(): Data {
    return {
      canvasDrawn: false,
      valide: false,
      edit: true,
      unterschreibbar: false,
      antworten: undefined,
      patientId: undefined,
      nichtLeerRegel: [(v: string) => !!v || "Dieses Feld ist ein Pflichfeld."],
      groesserNullRegel: [
        (v: string) => !!v || "Dieses Feld ist ein Pflichfeld.",
        (v: number) => (v && v > 0) || "Der Wert muss größer 0 sein.",
      ],
      tagesStundenRegel: [
        (v: number) => v >= 0 || "Der Wert muss größer/gleich 0 sein.",
        (v: number) => v < 25 || "Der Wert muss kleiner 25 sein.",
      ],
      opDatumMenu: false,
      opDatum: "",
      angabenDatum: "",
      canvasWidthFactor: 0,
      canvasHeightFactor: 0,
      canvasContext: undefined,
      canvasPoints: [],
      psychischBeeintraechtigt: null,
      schmerzStaerkeRuhe: null,
      schmerzStaerkeBelastung: null,
      patient: undefined,
      deleteDialog: false,
      deleted: false,
      awaitingDelete: false,
      viewingUserRole: Role.PATIENT,
      datenschutzCheckbox: false,
    };
  },
  props: {
    patientIdProp: {
      type: Number,
      default: null,
    },
  },
  created() {
    if (this.patientIdProp !== null) {
      this.patientId = this.patientIdProp;
    } else {
      this.patientId = this.$route.params.patientId;
    }
    this.getAntworten();
    this.viewingUserRole = this.$store.state.authentication.role;
    window.addEventListener("resize", this.drawCanvas);
  },
  updated() {
    this.drawCanvas();
  },
  methods: {
    resetCanvas() {
      this.canvasPoints = [];
      this.drawCanvas();
    },
    drawCanvas() {
      const canvasCol = document.getElementById(
        this.edit ? "canvasColEdit" : "canvasCol"
      ) as HTMLElement;
      if (canvasCol === null) {
        return;
      }
      const width = canvasCol.offsetWidth - 24; //Padding abziehen
      const canvas = document.getElementById(
        this.edit ? "schmerzenCanvasEdit" : "schmerzenCanvas"
      ) as HTMLCanvasElement;

      this.canvasContext = canvas.getContext("2d") as CanvasRenderingContext2D;

      const background = new Image();
      background.onload = () => {
        canvas.width = width;
        this.canvasWidthFactor = canvas.width / 1000;
        canvas.height =
          (background.naturalHeight / background.naturalWidth) * width;
        this.canvasHeightFactor = canvas.height / 1000;
        this.canvasContext.drawImage(
          background,
          0,
          0,
          canvas.width,
          canvas.height
        );
        for (const point of this.canvasPoints) {
          this.drawPoint(point, this.canvasContext);
        }
      };
      background.src = require("@/assets/dihs/Anamnese/DIHS_Silhoutten_Anamnese_03082020.jpg");
      canvas.onmousedown = (e) => {
        if (this.edit) {
          const point: Point = this.getMousePos(canvas, e);
          this.canvasPoints.push(point);
          this.drawPoint(point, this.canvasContext);
        }
      };
    },
    getMousePos(canvas: HTMLCanvasElement, evt: MouseEvent) {
      const rect = canvas.getBoundingClientRect();
      return {
        x: Math.floor((evt.clientX - rect.left) / this.canvasWidthFactor),
        y: Math.floor((evt.clientY - rect.top) / this.canvasHeightFactor),
      };
    },
    drawPoint(point: Point, ctx: CanvasRenderingContext2D) {
      if (ctx !== undefined) {
        ctx.beginPath();
        ctx.arc(
          point.x * this.canvasWidthFactor,
          point.y * this.canvasHeightFactor,
          20 * this.canvasHeightFactor,
          0,
          2 * Math.PI
        );
        ctx.fillStyle = "red";
        ctx.fill();
      }
    },
    getAntworten() {
      let url = "/anamnese";
      let patientUrl = "patient/getPatient";
      if (this.patientId !== undefined) {
        url = "/anamnese/other/" + this.patientId;
        patientUrl = "/patient/getByPatientId/" + this.patientId;
      }
      axios
        .get<AnamneseFragebogenDto>(url, Api.getRequestConfig())
        .then((response) => {
          if (response.data.woSchmerzen) {
            this.canvasPoints = JSON.parse(response.data.woSchmerzen);
          }
          this.antworten = response.data;
          this.edit = !response.data.unterschrieben;
          if (this.patientId !== undefined) {
            // Für andere öffne nicht im Edit-Mode
            this.edit = false;
          }
          this.unterschreibbar = !response.data.unterschrieben;
          this.angabenDatum = this.formatDate(response.data.angabenDatum);
          this.opDatum = response.data.opDatum;
          this.psychischBeeintraechtigt = this.antworten.psychischBeeintraechtigt;
          this.schmerzStaerkeRuhe = this.antworten.schmerzStaerkeRuhe;
          this.schmerzStaerkeBelastung = this.antworten.schmerzStaerkeBelastung;
        })
        .catch((e) => {
				  
          console.log(e);
        });
      axios.get(patientUrl, Api.getRequestConfig())
        .then((response) => {
          this.patient = response.data;
        })
        .catch(() => {
          
        });
    },
    exitEditMode() {
      this.edit = false;
      this.saveAntworten();
    },
    saveAntworten() {
      this.save(false);
    },
    deleteAntworten() {
      this.awaitingDelete = true;
      axios
        .post("/anamnese/entfernen", true, Api.getRequestConfig())
        .then(() => {
          this.deleted = true;
          this.awaitingDelete = false;
        })
        .catch(() => {
				  
        });
    },
    leaveDeleteDialog() {
      if (this.deleted) {
        this.$router
          .push({
            name: "Cockpit",
          })
          .catch(() => {});
      } else {
        this.deleteDialog = false;
      }
    },
    save(unterschreiben: boolean) {
      this.unterschreibbar = false;
      this.antworten.woSchmerzen = JSON.stringify(this.canvasPoints);
      if (this.antworten.psychischBeeintraechtigt !== null) {
        this.antworten.psychischBeeintraechtigt = this.psychischBeeintraechtigt;
      }
      if (this.antworten.schmerzStaerkeRuhe !== null) {
        this.antworten.schmerzStaerkeRuhe = this.schmerzStaerkeRuhe;
      }
      if (this.antworten.schmerzStaerkeBelastung !== null) {
        this.antworten.schmerzStaerkeBelastung = this.schmerzStaerkeBelastung;
      }
      axios
        .post("/anamnese", this.antworten, Api.getRequestConfig())
        .then(() => {
          if (unterschreiben) {
            this.antworten.unterschrieben = true;

            axios
              .get("/anamnese/unterschreiben", Api.getRequestConfig())
              .then((response) => {
                this.edit = false;
              })
              .catch((e) => { 
				        
                this.antworten.unterschrieben = false;
                this.unterschreibbar = true;
              });
          } else {
            this.unterschreibbar = true;
          }
        })
        .catch(() => {
          
        });
    },
    unterschreiben() {
      this.save(true);
    },
    document() {
      if (this.patientId !== undefined) {
        DokumentApi.getAnamneseFragebogen(this.patientId);
      } else {
        DokumentApi.getAnamneseFragebogen(null);
      }
    },
    editMode() {
      this.edit = true;
      this.drawCanvas();
    },
    storeNewOpDatum(obj: string) {
      this.$refs.opDatumMenu.save(obj);
    },
    parseDate(date: string) {
      if (!date) return null;
      const [day, month, year] = date.split(".");
      return `${year}-${("0" + month).slice(-2)}-${("0" + day).slice(-2)}`;
    },
    formatDate(date: string) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    hasText(string: string) {
      return string !== null && string !== undefined && string !== "";
    },
    appendElementToString(string: string, element: string) {
      if (this.hasText(string) && this.hasText(element)) {
        return string + ", " + element;
      } else if (this.hasText(string)) {
        return string;
      } else if (this.hasText(element)) {
        return element;
      } else {
        return "";
      }
    },
    formatSportart(sportart: string, sportartAntwort: string) {
      if (this.hasText(sportart)) {
        if (this.hasText(sportartAntwort)) {
          switch (sportartAntwort) {
            case "0":
              return sportart + " (gelegentlich)";
            case "1":
              return sportart + " (häufig)";
            case "2":
              return sportart + " (intensiv)";
          }
        }
        return sportart;
      } else {
        return "";
      }
    },
    formatSlider(value: number | null, min: number, max: number) {
      return value !== null && value <= max && value >= min
        ? value.toString() + "/" + max.toString()
        : "";
    },
    isPatientOrBetreiber(): boolean {
      return (
        Role.BETREIBER === this.viewingUserRole ||
        Role.PATIENT == this.viewingUserRole
      );
    },
  },
  computed: {
    sportarten() {
      const sportarten: string[] = [];
      sportarten.push(
        this.formatSportart(
          this.antworten.sportart1,
          this.antworten.sportart1antwort
        )
      );
      sportarten.push(
        this.formatSportart(
          this.antworten.sportart2,
          this.antworten.sportart2antwort
        )
      );
      sportarten.push(
        this.formatSportart(
          this.antworten.sportart3,
          this.antworten.sportart3antwort
        )
      );
      sportarten.push(
        this.formatSportart(
          this.antworten.sportart4,
          this.antworten.sportart4antwort
        )
      );
      let result = "";
      for (const sportart of sportarten) {
        result = this.appendElementToString(result, sportart);
      }
      return result;
    },
    behandelnderArzt() {
      let result = "";
      result = this.appendElementToString(result, this.antworten.baName);
      result = this.appendElementToString(result, this.antworten.baStrasse);
      result = this.appendElementToString(result, this.antworten.baStadt);
      result = this.appendElementToString(result, this.antworten.baTelefon);
      return result;
    },
    schmerzen() {
      if (this.hasText(this.antworten.schmerzen)) {
        switch (this.antworten.schmerzen) {
          case "0":
            return "ja";
          case "1":
            return "nein";
        }
      }
      return "";
    },
    seitWannSchmerzen() {
      if (this.hasText(this.antworten.seitWannSchmerzen)) {
        switch (this.antworten.seitWannSchmerzen) {
          case "0":
            return "Tage";
          case "1":
            return "Wochen";
          case "2":
            return "Monate";
          case "3":
            return "Jahre";
        }
      }
      return "";
    },
    schmerzArt() {
      let result = "";
      if (this.antworten.schmerzArt1) {
        result = this.appendElementToString(result, "spitz");
      }
      if (this.antworten.schmerzArt2) {
        result = this.appendElementToString(result, "stechend");
      }
      if (this.antworten.schmerzArt3) {
        result = this.appendElementToString(result, "schneidend");
      }
      if (this.antworten.schmerzArt4) {
        result = this.appendElementToString(result, "ziehend");
      }
      if (this.antworten.schmerzArt5) {
        result = this.appendElementToString(result, "oberflächlich");
      }
      if (this.antworten.schmerzArt6) {
        result = this.appendElementToString(result, "tief");
      }
      if (this.antworten.schmerzArt7) {
        result = this.appendElementToString(result, "bohrend");
      }
      if (this.antworten.schmerzArt8) {
        result = this.appendElementToString(result, "brennend");
      }
      if (this.antworten.schmerzArt9) {
        result = this.appendElementToString(result, "dumpf");
      }
      return result;
    },
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.drawCanvas);
  },
  watch: {
    opDatum() {
      this.antworten.opDatum = this.opDatum;
    },
    antworten() {
      if (!this.canvasDrawn) {
        this.canvasDrawn = true;
        this.$nextTick(function () {
          this.drawCanvas();
        });
      }
    },
  },
});
