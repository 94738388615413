import Api from "@/config/api";
import AboPaket from '@/data/AboPaket';
import AboBuchungDto from '@/dtos/AboBuchungDto';
import AboNoticeDto from '@/dtos/AboNoticeDto';
import AboPaketDto from '@/dtos/AboPaketDto';
import CreateAboPaketDto from '@/dtos/CreateAboPaketDto';
import axios from "axios";

export default {
	getBetreiberAllePakete() {
		const url: string = Api.getRequestConfig().baseURL + "/abo/betreiber/pakete/all";
		return axios
			.get<Array<AboPaketDto>>(url, { headers: Api.getRequestConfig().headers })
			.then(response => {
				const returnAbos: AboPaket[] = []
				response.data.forEach(abo => {
					returnAbos.push(
						new AboPaket(
							abo.aboPaketId,
							abo.trainingsplaene,
							abo.befundtermine,
							abo.einzelTermineProQuartal,
							abo.gruppenTermineProQuartal,
							abo.laufzeitMonate,
							abo.buchungMonate,
							abo.kostenProQuartalCent,
							abo.title,
							abo.kuendigungsfristMonate,
							abo.aktiv,
							abo.buchbar
						)
					);
				});
				return returnAbos;
			})
			.catch(error => {
				
				console.log(error);
				return [];
			});

	},
	storeAboPaket(paket: CreateAboPaketDto) {
		const url: string =
			Api.getRequestConfig().baseURL + "/abo/betreiber/pakete/add";
		return axios
			.post(url, paket, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				
				console.log(error);
				return null
			});
	},
	changeAboPaket(paket: AboPaketDto) {
		const url: string =
			Api.getRequestConfig().baseURL + "/abo/betreiber/pakete/change";
		axios
			.post(url, paket, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				
				console.log(error);
				return null
			});
	},
	ladeAktivesPatientenAbo() {
		const url = "/abo/patient/aktiv";
		return axios
			.get<AboBuchungDto>(url, Api.getRequestConfig())
			.then(response => {
					return response.data;
			})
			.catch(() => {
				
				return null
			});
	},
	ladePaymentUrl() {
		const url = "/abo/patient/paymentLink";
		return axios
			.get<string>(url, Api.getRequestConfig())
			.then(response => {
					return response.data;
			})
			.catch(() => {
				
				return null
			});
	},
	ladeAktivesPatientenAboBetreiber(patientId: number) {
		const url = "/abo/betreiber/"+patientId+"/aktiv";
		return axios
			.get<AboBuchungDto>(url, Api.getRequestConfig())
			.then(response => {
					return response.data;
			})
			.catch(() => {
				
				return null
			});
	},
	getAboBezahlungHinweise() {
		const url: string = Api.getRequestConfig().baseURL + "/abo/patient/paymentNotice";
		return axios
			.get<Array<AboNoticeDto>>(url, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				
				console.log(error);
				return null;
			});

	},
	abopaketZuweisen(paketId: number, patientId: number) {
		const url: string =
			Api.getRequestConfig().baseURL + "/abo/betreiber/abozuweisen";
			const data = {aboPaketId: paketId, patientId: patientId};
		return axios
			.post(url, data, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				
				console.log(error);
				return null
			});
	},
	betreiberBezahlAboBeenden(patientId: number, sofort: boolean) {
		const url: string =
			Api.getRequestConfig().baseURL + "/abo/betreiber/abobeenden/bezahlabo";
			const data = {sofort: sofort, patientId: patientId};
		return axios
			.post(url, data, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				
				console.log(error);
				return null
			});
	},
	betreiberFirmenAboBeenden(patientId: number, date: Date) {
		const url: string =
			Api.getRequestConfig().baseURL + "/abo/betreiber/abobeenden/firmenabo";
			const data = {date: date, patientId: patientId};
		return axios
			.post(url, data, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				
				console.log(error);
				return null
			});
	},
};
