import Api from "@/config/api";
import axios from "axios";
import { saveAs } from "file-saver";

export default {
	getMyRezepte() {
		const url: string =
			Api.getRequestConfig().baseURL + "/rezepte/mine";
		return axios
			.get(url, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				
				console.log(error);
				return null
			});
	},
	getRezepteForPatient(patientId: any) {
		const url: string =
			Api.getRequestConfig().baseURL + "/rezepte/for/" + patientId;
		return axios
			.get(url, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				
				console.log(error);
				return null
			});
	},
	getAllRezepte() {
		//THIS GETS 100 MOST RECENT REZEPTE!!!
		const url: string =
			Api.getRequestConfig().baseURL + "/rezepte/all";
		return axios
			.get(url, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				
				console.log(error);
				return null
			});
	},
	getNumberOfRezepte(count: number) {
		//this gets you the specified amount of rezepte...
		const url: string =
			Api.getRequestConfig().baseURL + "/rezepte/all/" + count;
		return axios
			.get(url, { headers: Api.getRequestConfig().headers })
			.then(response => {
				return response.data;
			})
			.catch(error => {
				
				console.log(error);
				return null
			});
	},
	downloadRezeptFile(fileId: number, filename: string, extension: string) {
		const url: string =
			Api.getRequestConfig().baseURL + "/rezepte/" + fileId;
		return axios
			.get(url, { headers: Api.getRequestConfig().headers, responseType: 'blob' })
			.then(response => {
				this.dateiLaden(response.data, filename, extension);
			})
			.catch(error => {
				
				console.log(error);
				return null
			});
	},
	downloadRezeptFileAsPatient(fileId: number, filename: string, extension: string) {
		const url: string =
			Api.getRequestConfig().baseURL + "/rezepte/my/" + fileId;
		return axios
			.get(url, { headers: Api.getRequestConfig().headers, responseType: 'blob' })
			.then(response => {
				this.dateiLaden(response.data, filename, extension);
			})
			.catch(error => {
				
				console.log(error);
				return null
			});
	},
	dateiLaden(blobContent: string, fileName: string, fileType: string) {
		//This saves a PDF with the right file name and extension but its always empty...
		if (fileType === "pdf") {
			fileType = "application/pdf"
		} else if (fileType === "jpg" || fileType === "png" || fileType === "jpeg") {
			fileType = "image/" + fileType;
		}
		const blob = new Blob([blobContent], { type: fileType });
		saveAs(blob, fileName);
	}
}