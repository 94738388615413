//please try to keep this alphabetical
import BetreiberTermine from "@/components/Termin/Betreiber/BetreiberTermine.vue";
import PatientTermine from "@/components/Termin/Patient/PatientTermine.vue";
import PatientTermineBetreibersicht from "@/components/Termin/Patient/PatientTermineBetreibersicht.vue";
import TherapeutTermineComponent from "@/components/Termin/Therapeut/TherapeutTermine.vue";
import EmailBestaetigenComponent from "@/components/email/EmailBestaetigen.vue";
import EmailResetComponent from "@/components/email/EmailReset.vue";
import PasswortResetComponent from "@/components/passwort/PasswortReset.vue";
import PaymentRedirect from "@/components/payment/PaymentRedirect.vue";
import TherapeutenListeComponent from "@/components/therapeut/TherapeutenListe.vue";
import TrainingsPlanVorlagen from "@/components/trainingsplan/TrainingsPlanVorlagen.vue";
import NavigationEditComponent from "@/components/verwaltung/NavigationEdit.vue";
import Vue from "vue";
import Router from "vue-router";
import ErrorPageComponent from "./components/ErrorPageComponents/ErrorPageComponent.vue";
import PatientFrageboegen from "./components/PatientFrageboegen/PatientFrageboegen.vue";
import AppVideoPatientView from "./components/VideoTherapie/AppVideoPatientView.vue";
import Verbindungstest from "./components/VideoTherapie/Verbindungstest.vue";
import VideoPatientView from "./components/VideoTherapie/VideoPatientView.vue";
import VideoTherapeutView from "./components/VideoTherapie/VideoTherapeutView.vue";
import AboPaketVerwaltungBetreiber from './components/abo/betreiber/AboPaketVerwaltungBetreiber.vue';
import AboBuchungPatient from "./components/abo/patient/AboBuchungPatient.vue";
import AllgemeineVideos from './components/allgemeineVideos/AllgemeineVideos.vue';
import AnamneseFragebogenComponent from "./components/anamnesefragebogen/AnamneseFragebogen.vue";
import BefundFragebogenBetreiberSeiteComponent from "./components/befundfragebogen/BefundFragebogenBetreiberSeite.vue";
import CartView from "./components/cart/CartView.vue";
import CockpitComponent from "./components/cockpit/Cockpit.vue";
import FirmaVerwaltung from "./components/firmen/FirmaVerwaltung.vue";
import LandingPage from "./components/inhalt/LandingPage.vue";
import SeiteComponent from "./components/inhalt/Seite.vue";
import NutzerProfil from "./components/profil/NutzerProfil.vue";
import RegistrierungsFormularComponent from "./components/registrierung/RegistrierungsFormular.vue";
import RezeptUploadUndListePatient from "./components/rezepte/RezeptUploadUndListePatient.vue";
import RezeptViewBetreiber from "./components/rezepte/RezeptViewBetreiber.vue";
import GuthabenBuchung from "./components/shop/GuthabenBuchung.vue";
import GuthabenKaufPatient from "./components/shop/GuthabenKaufPatient.vue";
import PatientTrainingsplanComponent from "./components/trainingsplan/PatientTrainingsplan.vue";
import TherapeutTrainingsplanComponent from "./components/trainingsplan/TherapeutTrainingsplan.vue";
import UebungenComponent from "./components/uebung/Uebungen.vue";
import BetreiberUpload from "./components/upload/BetreiberUpload.vue";
import Verwaltung from "./components/verwaltung/Anwendungsverwaltung.vue";
import Statistik from './components/verwaltung/StatistikHerunterladen.vue';
import UserListComponent from "./components/verwaltung/UserList.vue";
import { Privilege } from "./data/user/Privilege";
import { Role } from "./data/user/Role";
import { store } from './store';

Vue.use(Router);

const router = new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{ path: "/", name: "Startseite", component: LandingPage },
		{ path: "/cockpit", name: "Cockpit", component: CockpitComponent, meta: { loginRequired: true, roleRequired: Role.PATIENT } },
		{
			path: "/registrieren",
			name: "Registrieren",
			component: RegistrierungsFormularComponent,
		},
		{ path: "/profil", name: "Profil", component: NutzerProfil, meta: { pageTitle: "Profil", loginRequired: true } },
		{ path: "/profil/:userId", name: "NutzerProfil", component: NutzerProfil, meta: { pageTitle: "Profil", loginRequired: true } },
		{
			path: "/betreiber/fragebogen",
			name: "Befund-Fragebogen",
			component: BefundFragebogenBetreiberSeiteComponent,
			meta: { loginRequired: true, roleRequired: Role.BETREIBER }
		},
		{ path: "/inhalt/:url", name: "Seite", component: SeiteComponent },
		{ path: "/agb", name: "AGB", component: SeiteComponent },
		{ path: "/faq", name: "FAQ", component: SeiteComponent },
		{ path: "/impressum", name: "Impressum", component: SeiteComponent },
		{ path: "/datenschutz", name: "Datenschutz", component: SeiteComponent },
		{ path: "/kontakt", name: "Kontakt", component: SeiteComponent },
		{ path: "/neuigkeiten", name: "Neuigkeiten", component: SeiteComponent },
		{ path: "/einverstaendnis_videotherapie", name: "Einverständnis zur Videotherapie", component: SeiteComponent },
		{ path: "/nutzungsbestimmungen", name: "Nutzungsbestimmungen", component: SeiteComponent },
		{ path: "/sturz", name: "Sturz", component: SeiteComponent },
		{
			path: "/navigation",
			name: "Navigation Editieren",
			component: NavigationEditComponent,
			meta: { loginRequired: true, roleRequired: Role.BETREIBER, privilegeRequired: Privilege.NAVIGATION_EDITIEREN }
		},
		{
			path: "/anamnese",
			name: "anamneseFragebogen",
			component: AnamneseFragebogenComponent,
			meta: { pageTitle: "Anamnesefragebogen", loginRequired: true }
		},
		{
			path: "/videos",
			name: "videos",
			component: AllgemeineVideos,
			meta: { pageTitle: "Allgemeine Videos", loginRequired: true }
		},
		{
			path: "/anamnese/:patientId",
			name: "anamneseFragebogenPatient",
			component: AnamneseFragebogenComponent,
			meta: { pageTitle: "Anamnesefragebogen", loginRequired: true }
		},
		{
			path: "/patient/fragebogen/:patientId",
			name: "frageboegen",
			component: PatientFrageboegen,
			meta: { pageTitle: "Fragebogen", loginRequired: true, }
		},
		{
			path: "/betreiber/upload",
			name: "betreiberUpload",
			component: BetreiberUpload,
			meta: { pageTitle: "Dateien hochladen", loginRequired: true, roleRequired: Role.BETREIBER }
		},
		{
			path: "/emailbestaetigen/:code",
			name: "E-Mail bestätigen",
			component: EmailBestaetigenComponent,
		},
		{ path: "/emailreset/:code", name: "E-Mail zuruecksetzen", component: EmailResetComponent, meta: { pageTitle: "E-Mail zurücksetzen" } },
		{ path: "/passwortreset/:code", name: "Passwort zuruecksetzen", component: PasswortResetComponent, meta: { pageTitle: "Passwort zurücksetzen" } },
		{
			path: "/betreiber/termine",
			name: "Betreiber: Termine bearbeiten",
			component: BetreiberTermine,
			meta: { loginRequired: true, roleRequired: Role.BETREIBER }
		},
		{
			path: "/therapeut/termine",
			name: "therapeutTermine",
			component: TherapeutTermineComponent,
			meta: { pageTitle: "Therapeut: Termine", loginRequired: true, roleRequired: Role.THERAPEUT }
		},
		{
			path: "/therapeuten",
			name: "therapeutenListe",
			component: TherapeutenListeComponent,
			meta: { pageTitle: "Therapeutenliste", loginRequired: true, roleRequired: Role.PATIENT }
		},
		{
			path: "/betreiber/benutzer/liste",
			name: "Betreiber: Benutzer verwalten",
			component: UserListComponent,
			meta: { loginRequired: true, roleRequired: Role.BETREIBER, privilegeRequired: Privilege.BENUTZER_VERWALTEN },
		},
		{
			path: "/patient/termine",
			name: "Patient: Termine",
			component: PatientTermine,
			meta: { pageTitle: "Termine", roleRequired: Role.PATIENT, loginRequired: true },
		},
		{
			path: "/betreiber/termine/patient/:patient",
			name: "Betreiber: Termine für Patient",
			component: PatientTermineBetreibersicht,
			meta: { pageTitle: "Patienttermine Betreibersicht", loginRequired: true, roleRequired: Role.BETREIBER }
		},
		{
			path: "/therapeut/trainingsplan/:patient",
			alias: "/betreiber/trainingsplan/:patient",
			name: "Therapeut: Trainingsplan",
			component: TherapeutTrainingsplanComponent,
			meta: { loginRequired: true, anyRoleRequired: [Role.THERAPEUT, Role.BETREIBER] }
		},
		{
			path: "/therapeut/trainingsplanvorlagen",
			name: "TrainingsplanVorlagen",
			component: TrainingsPlanVorlagen,
			meta: { pageTitle: "Trainingsplan Vorlagen", loginRequired: true, roleRequired: Role.THERAPEUT }
		},
		{
			path: "/patient/trainingsplan",
			name: "Patient: Trainingsplan",
			component: PatientTrainingsplanComponent,
			meta: { pageTitle: "Trainingsplan", loginRequired: true, roleRequired: Role.PATIENT }
		},
		{
			path: "/betreiber/uebungen",
			name: "Uebungen: Verwalten",
			component: UebungenComponent,
			meta: { pageTitle: "Übungen verwalten", loginRequired: true, anyPrivilegeRequired: [Privilege.UEBUNGEN_VERWALTEN_THERAPEUT, Privilege.UEBUNGEN_VERWALTEN] }
		},
		{
			path: "/betreiber/verwaltung",
			name: "Anwendung: Verwalten",
			component: Verwaltung,
			meta: { loginRequired: true, roleRequired: Role.BETREIBER, privilegeRequired: Privilege.ANWENDUNG_VERWALTEN }
		},
		{
			path: "/betreiber/statistik",
			name: "Statistik: Herunterladen",
			component: Statistik,
			meta: { loginRequired: true, roleRequired: Role.BETREIBER, privilegeRequired: Privilege.STATISTIK_LESEN }
		},
		{
			path: "/betreiber/firmenverwaltung",
			name: "Firmen: Verwaltung",
			component: FirmaVerwaltung,
			meta: { loginRequired: true, roleRequired: Role.BETREIBER, privilegeRequired: Privilege.BENUTZER_VERWALTEN }
		},
		/*{	//nicht belegt da die Seite nicht umgesetzt ist.
			path: "/betreiber/zahlungen",
			name: "Zahlungen: Verwalten",
			component: Zahlungen,
			meta: { loginRequired: true, roleRequired: Role.BETREIBER, privilegeRequired: Privilege.ZAHLUNGEN_VERWALTEN }
		},*/
		{
			path: "/video/therapie/therapeut/:conferenceId",
			name: "videosessionTherapeut",
			component: VideoTherapeutView,
			meta: { pageTitle: "Videosession", loginRequired: true, roleRequired: Role.THERAPEUT }
		},
		{
			path: "/video/therapie/patient/:conferenceId",
			name: "videosessionPatient",
			component: VideoPatientView,
			meta: { pageTitle: "Videosession", loginRequired: true, roleRequired: Role.PATIENT }
		},
		{
			path: "/video/therapie/app/patient/:conferenceId/:bearer",
			name: "appVideosessionPatient",
			component: AppVideoPatientView,
			meta: { pageTitle: "Videosession", loginRequired: true, roleRequired: Role.PATIENT }
		},
		{
			path: "/verbindungstest",
			name: "verbindungstest",
			component: Verbindungstest,
			meta: { pageTitle: "Verbindungstest", loginRequired: true }
		},
		{
			path: "/payment/redirect",
			name: "paymentRedirect",
			component: PaymentRedirect,
			meta: { pageTitle: "Zahlung: Weiterleitung" }
		},
		{
			path: "/payment/:id",
			name: "CartView",
			component: CartView,
			meta: { pageTitle: "Buchungsvorgänge" }
		},
		{
			path: "/patient/guthaben",
			name: "GuthabenBuchung",
			component: GuthabenKaufPatient,
			meta: { pageTitle: "Guthabenbuchung - Shop", loginRequired: true, roleRequired: Role.PATIENT }
		},
		{
			path: "/patient/abo",
			name: "AboBuchung",
			component: AboBuchungPatient,
			meta: { pageTitle: "AboBuchung - Shop", loginRequired: true, roleRequired: Role.PATIENT }
		},
		{
			path: "/guthaben/:id",
			name: "GuthabenBuchungStatus",
			component: GuthabenBuchung,
			meta: { pageTitle: "Guthabenbuchung - Status", loginRequired: true }
		},
		{
			path: "/betreiber/abo/pakete",
			name: "BetreiberAboPakete",
			component: AboPaketVerwaltungBetreiber,
			meta: { pageTitle: "AboPakete - Verwaltung", loginRequired: true, roleRequired: Role.BETREIBER, privilegeRequired: Privilege.ABOPAKETE_VERWALTEN }
		},
		{
			path: "/patient/rezepte",
			name: "PatientRezepte",
			component: RezeptUploadUndListePatient,
			meta: { pageTitle: "Ihre Rezepte", loginRequired: true, roleRequired: Role.PATIENT }
		},
		{
			path: "/betreiber/rezepte/:patientId",
			name: "PatientRezepteBetreibersicht",
			component: RezeptViewBetreiber,
			meta: { pageTitle: "Rezepte", loginRequired: true, anyRoleRequired: [Role.THERAPEUT, Role.BETREIBER]  }
		},
		{
			path: "/betreiber/rezepte",
			name: "RezeptUebersicht",
			component: RezeptViewBetreiber,
			meta: { pageTitle: "Alle Rezepte", loginRequired: true, roleRequired: Role.BETREIBER }
		},
		{ path: "/error/:errorId", name: "Fehler", component: ErrorPageComponent },
		{ path: "/*", name: "FehlendeSeite", redirect: '/error/404' },
	],
});

router.beforeEach((to, from, next) => {


	if (to.matched.some(record => record.meta.loginRequired)) {

		if (!store.getters.loggedIn) {
			next("/")
			return
		}

	}

	if (to.matched.some(record => record.meta.privilegeRequired)) {
		if (!store.getters.hasPrivilege(to.meta.privilegeRequired)) {

			if (store.getters.loggedIn) {
				if (store.getters.isRole(Role.PATIENT)) {
					next("/cockpit")
				} else if (store.getters.isRole(Role.THERAPEUT)) {
					next("/therapeut/termine")
				} else if (store.getters.isRole(Role.BETREIBER)) {
					next("/")
				}
			} else {
				next("/")
			}
		}
	}

	if (to.meta.roleRequired != undefined) {
		if (!store.getters.isRole(to.meta.roleRequired)) {

			if (store.getters.loggedIn) {
				if (store.getters.isRole(Role.PATIENT)) {
					next("/cockpit")
				} else if (store.getters.isRole(Role.THERAPEUT)) {
					next("/therapeut/termine")
				} else if (store.getters.isRole(Role.BETREIBER)) {
					next("/")
				}
			} else {
				next("/")
			}
		}
	}

	if (to.meta.anyRoleRequired != undefined) {
		let hasRole = false
		to.meta.anyRoleRequired.forEach((element: any) => {
			if (store.getters.isRole(element)) hasRole = true;
		});
		if (!hasRole) {

			if (store.getters.loggedIn) {
				if (store.getters.isRole(Role.PATIENT)) {
					next("/cockpit")
				} else if (store.getters.isRole(Role.THERAPEUT)) {
					next("/therapeut/termine")
				} else if (store.getters.isRole(Role.BETREIBER)) {
					next("/")
				}
			} else {
				next("/")
			}
		}
	}

	if (to.meta.anyPrivilegeRequired != undefined) {
		let hasPrivilege = false
		to.meta.anyPrivilegeRequired.forEach((element: any) => {
			if (store.getters.hasPrivilege(element)) hasPrivilege = true;
		});
		if (!hasPrivilege) {

			if (store.getters.loggedIn) {
				if (store.getters.isRole(Role.PATIENT)) {
					next("/cockpit")
				} else if (store.getters.isRole(Role.THERAPEUT)) {
					next("/therapeut/termine")
				} else if (store.getters.isRole(Role.BETREIBER)) {
					next("/")
				}
			} else {
				next("/")
			}
		}
	}
	next()
})

export default router;
