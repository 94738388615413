













































































































































































































































































































import SeiteComponent from "@/components/inhalt/Seite.vue";
import Api from "@/config/api";
import NavigationseintragFullDto from "@/data/inhaltsseite/NavigationseintragFullDto";
import NavigationselementDto from "@/data/inhaltsseite/NavigationselementDto";
import NavigationsmenueDto from "@/data/inhaltsseite/NavigationsmenueDto";
import { UrlType } from "@/dtos/UrlType";
import helper from "@/helper/types";
import axios from "axios";
import Vue from "vue";
interface Data {
  navigation: NavigationsmenueDto[];
  menues: any;
  active: NavigationselementDto[];
  selected: any;
  neueSeiteTitel: string;
  neuesUntermenueTitel: string;
  neueSeiteAktiv: boolean;
  neueSeiteUrl: string;
  neueSeiteTitelGueltig: boolean;
  neuesUntermenueTitelGueltig: boolean;
  neueSeiteUrlGueltig: boolean;
  neueSeiteTitelRegeln: any;
  neueSeiteUrlRegeln: any;
  neuesUntermenueTitelRegeln: any;
  neuesUntermenueTopLevel: boolean;
  newMenueId: number;
  dialog: boolean;
  dialogStatus: number;
  toDelete: NavigationseintragFullDto[] | undefined;
  seiteDialog: boolean;
  deleteSeiteDialog: boolean;
}
export default Vue.extend({
  data(): Data {
    return {
      navigation: [],
      menues: {},
      active: [],
      selected: undefined,
      neueSeiteTitel: "",
      neuesUntermenueTitel: "",
      neueSeiteAktiv: true,
      neueSeiteUrl: "",
      neueSeiteTitelGueltig: false,
      neuesUntermenueTitelGueltig: false,
      neueSeiteUrlGueltig: false,
      neueSeiteTitelRegeln: [
        (titel: string) => !!titel || "Titel darf nicht leer sein.",
      ],
      neuesUntermenueTitelRegeln: [
        (titel: string) => !!titel || "Titel darf nicht leer sein.",
      ],
      neueSeiteUrlRegeln: [
        (url: string) => !!url || "Url darf nicht leer sein.",
      ],
      neuesUntermenueTopLevel: false,
      newMenueId: 0,
      dialog: false,
      dialogStatus: 0,
      toDelete: undefined,
      seiteDialog: false,
      deleteSeiteDialog: false,
    };
  },
  components: {
    SeiteComponent,
  },
  created() {
    this.neueSeiteUrlRegeln.push(
      (url: string) => this.isUrlFree(url) || "Diese Url ist schon belegt."
    );
    this.getFullNavigation();
  },
  computed: {
    allMenues() {
      if (helper.isEintrag(this.selected.element)) {
        return this.getAllMenues();
      } else {
        return this.getAllMenues(2 - this.getSublevel(this.selected.element));
      }
    },
    url() {
      return this.selected.element.urlDto.statisch
        ? "/" + this.selected.element.urlDto.ziel
        : "/inhalt/" + this.selected.element.urlDto.ziel;
    },
  },
  watch: {
    active() {
      if (!this.active.length) {
        this.selected = undefined;
      }
      const id = this.active[0];
      this.selected = this.findElement(id);
    },
  },
  methods: {
    generateNewMenueId() {
      this.newMenueId = this.newMenueId + 1;
      return "n" + this.newMenueId;
    },
    getFullNavigation() {
      axios
        .get<NavigationsmenueDto[]>("/navigation/edit", Api.getRequestConfig())
        .then(response => {
          this.navigation = response.data;
          this.generateMenues();
        })
        .catch(() => {
          
        });
    },
    getSublevel(element: NavigationselementDto) {
      if (helper.isMenue(element)) {
        let sublevel = 0;
        for (const uelement of element.kinder) {
          if (helper.isMenue(uelement)) {
            const tmp = this.getSublevel(uelement);
            if (tmp > sublevel) {
              sublevel = tmp;
            }
          }
        }
        return 1 + sublevel;
      } else {
        return 0;
      }
    },
    generateMenues() {
      this.menues = {};
      for (const menue of this.navigation) {
        this.menues[menue.id] = "root";
        for (const eintrag of menue.kinder) {
          this.menues[eintrag.id] = menue.id;
          if (eintrag.kinder !== undefined) {
            for (const ueintrag of eintrag.kinder) {
              this.menues[ueintrag.id] = eintrag.id;
              if (ueintrag.kinder !== undefined) {
                for (const uueintrag of ueintrag.kinder) {
                  this.menues[uueintrag.id] = ueintrag.id;
                }
              }
            }
          }
        }
      }
    },
    getAllMenues(
      maxLevel = 2,
      menues: NavigationselementDto[] = this.navigation
    ) {
      let erg: any = [];
      if (helper.isMenue(this.selected.element)) {
        erg.push({ id: "root", titel: "Top-Level" });
      }
      if (maxLevel < 0) {
        return erg;
      }
      for (const element of menues) {
        if (
          helper.isMenue(element) &&
          element.id !== this.selected.element.id
        ) {
          erg.push({ id: element.id, titel: element.titel });
          erg = erg.concat(this.getAllMenues(maxLevel - 1, element.kinder));
        }
      }
      return erg;
    },
    newSeite() {
      const eintrag: NavigationseintragFullDto = {
        titel: this.neueSeiteTitel,
        aktiv: this.neueSeiteAktiv,
        id: this.generateNewMenueId(),
        urlDto: {
          urlType: UrlType.SEITE,
          ziel: this.neueSeiteUrl,
          statisch: false,
          params: {},
          toCreate: true,
        },
        type: "eintrag",
      };
      this.selected.element.kinder.push(eintrag);
      this.neueSeiteTitel = "";
      this.neueSeiteAktiv = false;
      this.neueSeiteUrl = "";
      this.menues[eintrag.id] = this.selected.element.id;
    },
    newMenue() {
      const menue: NavigationsmenueDto = {
        titel: this.neuesUntermenueTitel,
        id: this.generateNewMenueId(),
        kinder: [],
        type: "menue",
      };
      if (!this.neuesUntermenueTopLevel) {
        this.selected.element.kinder.push(menue);
        this.menues[menue.id] = this.selected.element.id;
      } else {
        this.navigation.push(menue);
        this.menues[menue.id] = "root";
      }
      this.neuesUntermenueTitel = "";
      this.neuesUntermenueTopLevel = false;
    },
    showSeite() {
      this.$router.push({
        name: "Seite",
        params: { seite: this.selected.element.urlDto.ziel },
      });
    },
    save() {
      axios
        .post<NavigationseintragFullDto[]>(
          "/navigation/edit",
          this.navigation,
          Api.getRequestConfig()
        )
        .then(response => {
          const toDelete: NavigationseintragFullDto[] = response.data;
          if (toDelete.length > 0) {
            this.dialogStatus = 2;
            this.toDelete = toDelete;
            this.dialog = true;
          } else {
            this.dialogStatus = 0;
            this.dialog = true;
          }
        })
        .catch(() => {
          
          this.dialogStatus = 1;
          this.dialog = true;
        });
    },
    reset() {
      this.getFullNavigation();
      this.active = [];
    },
    deleteSeiten() {
      this.dialogStatus = 3;
      this.dialog = true;
      axios
        .get("/navigation/emptytrash", Api.getRequestConfig())
        .then(() => {
          this.dialogStatus = 0;
          this.dialog = true;
        })
        .catch(() => {
          
          this.dialogStatus = 1;
          this.dialog = true;
        });
    },
    closeDialog() {
      this.reset();
      this.$store.state.menueChanged = true;
      this.dialog = false;
    },
    isUrlFree(
      url: string,
      menue: NavigationsmenueDto[] = this.navigation
    ): boolean {
      for (const element of menue) {
        if (
          helper.isEintrag(element) &&
          element.urlDto.ziel.toLowerCase() === url.toLowerCase()
        ) {
          return false;
        }
        if (helper.isMenue(element)) {
          const erg = this.isUrlFree(url, element.kinder);
          if (!erg) {
            return erg;
          }
        }
      }
      return true;
    },
    findElement(
      id: string,
      menue: NavigationsmenueDto[] = this.navigation,
      level = 0,
      vaterId: string | undefined = "root"
    ) {
      if (id === "root") {
        const root: NavigationsmenueDto = {
          titel: "Top-Level",
          id: "root",
          kinder: this.navigation,
          type: "menue",
        };
        return {
          menue: undefined,
          element: root,
          level: -1,
          vaterId: undefined,
        };
      }
      for (const element of menue) {
        if (element.id === id) {
          return {
            menue: menue,
            element: element,
            level: level,
            vaterId: vaterId,
          };
        }
        if (helper.isMenue(element)) {
          const erg = this.findElement(
            id,
            element.kinder,
            level + 1,
            element.id
          );
          if (erg !== undefined) {
            return erg;
          }
        }
      }
      return undefined;
    },
    moveElement(id: string, up: boolean) {
      const menue = this.findElement(id).menue;
      for (let i = 0; i < menue.length; i++) {
        const element = menue[i];
        if (
          element.id === id &&
          !(up && i === 0) &&
          !(!up && i === menue.length - 1)
        ) {
          menue.splice(i, 1);
          if (up) {
            menue.splice(i - 1, 0, element);
          } else {
            menue.splice(i + 1, 0, element);
          }
          break;
        }
      }
      this.active = [];
    },
    changeMenue() {
      const elementRes = this.selected;
      const id = elementRes.element.id;
      const menueId: number = this.menues[id];
      const newMenue = this.findElement(menueId).element;
      for (let i = 0; i < elementRes.menue.length; i++) {
        if (elementRes.menue[i].id === id) {
          elementRes.menue.splice(i, 1);
          break;
        }
      }
      newMenue.kinder.push(elementRes.element);
    },
    deleteElement() {
      const elementRes = this.selected;
      this.active = [];
      const id = elementRes.element.id;
      for (let i = 0; i < elementRes.menue.length; i++) {
        if (elementRes.menue[i].id === id) {
          elementRes.menue.splice(i, 1);
          if (helper.isMenue(elementRes.element)) {
            elementRes.menue.splice(i, 0, ...elementRes.element.kinder);
            for (const element of elementRes.element.kinder) {
              this.menues[element.id] = elementRes.vaterId;
            }
          }
          break;
        }
      }
    },
  },
});
