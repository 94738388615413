




























































import Api from "@/config/api";
import Firma from "@/data/Firma.ts";
import FirmaForPatient from "@/data/FirmaForPatient";
import axios from "axios";
import { mapGetters } from "vuex";

interface Data {
  firma: Firma | null;
  allFirmen: Firma[] | null;
  allFirmenNames: String[] | null;
  myFirmaName: String;
  edit: Boolean;
  dialogEdit: Boolean;
  dialogError: Boolean;
}

export default {
  components: {},
  props: {
    user: {
      type: Object,
      default: null,
    },
    profilViewType: {
      type: Number,
      default: null,
    },
    patientId: {
      type: Number,
      default: null,
    },
  },
  data(): Data {
    return {
      firma: null,
      allFirmen: null,
      allFirmenNames: null,
      myFirmaName: "",
      edit: false,
      dialogEdit: false,
      dialogError: false,
    };
  },
  created() {
    this.getInfo();
    this.allFirmenNames = [];
    if (this.profilViewType === 1) {
      this.getAllFirmen();
    }
  },
  methods: {
    getInfo() {
      let url = "firma/";
      if (this.profilViewType === 1) {
        url += "eine/patient/" + this.patientId;
      } else if (this.profilViewType === 0) {
        url += "meine";
      }
      axios
        .get<Firma>(url, Api.getRequestConfig())
        .then((response) => {
          if (response !== null && response.data != null && response.data != undefined) {
            this.firma = response.data;
            if (this.firma.id == null || this.firma.id == undefined) {
              this.firma = null;
            } else {
              this.myFirmaName = this.firma.bezeichner;
            }
          }
        })
        .catch((errorResponse) => {
          
          console.log(errorResponse);
        });
    },
    getAllFirmen() {
      const url = "firma/alle";
      axios
        .get(url, Api.getRequestConfig())
        .then((response) => {
          if (response !== null && response.data != null && response.data != undefined) {
            this.allFirmen = response.data;
            this.allFirmen.forEach((firma: Firma) => {
              this.allFirmenNames.push(firma.bezeichner);
            });
            this.allFirmenNames.push("Keine Firmenzugehörigkeit");
          }
        })
        .catch((errorResponse) => {
          
          console.log(errorResponse);
        });
    },
    saveChangedFirma(firma: Firma | null) {
      const url = "firma/setzen/patient";
      if (firma === null) {
        console.log("Removing Firma from Patient...");
        axios
          .post(
            url,
            new FirmaForPatient("Keine", -1, this.patientId),
            Api.getRequestConfig()
          )
          .catch((error: Error) => {
            
            this.$store.commit("setNetworkError");
          });
      } else {
        axios
          .post(
            url,
            new FirmaForPatient(
              this.firma.bezeichner,
              this.firma.id,
              this.patientId
            ),
            Api.getRequestConfig()
          )
          .catch((error: Error) => {
            
            this.$store.commit("setNetworkError");
          });
      }
    },
    changeEdit() {
      if (!this.isMe(this.user.id) && this.profilViewType === 1) {
        if (!this.edit) {
          this.edit = true;
        } else {
          this.dialogEdit = true;
        }
      } else {
        this.dialogError = true;
      }
    },
    editSave() {
      if (this.profilViewType === 1) {
        if (this.myFirmaName === "Keine Firmenzugehörigkeit") {
          //remove attached firma
          this.firma = null;
        } else {
          this.allFirmen.forEach((firma: Firma) => {
            if (this.myFirmaName === firma.bezeichner) {
              this.firma = firma;
            }
          });
        }
        this.saveChangedFirma(this.firma);
        this.dialogEdit = false;
        this.edit = false;
      }
    },
    editDiscard() {
      this.dialogEdit = false;
      this.edit = false;
    },
    editBack() {
      this.dialogEdit = false;
    },
  },
  computed: {
    ...mapGetters(["isMe"]),
  },
  watch: {},
};
