/* Muss übereinstimmen mit dem Privilege-Enum im Backend */
export enum Privilege {
	HAT_ABO = 1,

	NAVIGATION_EDITIEREN = 3,
	FRAGEBOGEN_EDITIEREN = 4,
	INHALT_EDITIEREN = 5,
	TERMINE_EDITIEREN = 6,
	BENUTZER_VERWALTEN = 7,
	DATEIEN_HOCHLADEN = 11,
	UEBUNGEN_VERWALTEN = 12,
	ANWENDUNG_VERWALTEN = 13,
	ZAHLUNGEN_VERWALTEN = 14,
	ABOPAKETE_VERWALTEN = 15,
	GUTHABEN_AENDERN = 16,
	UEBUNGEN_VERWALTEN_THERAPEUT = 17,
	PATIENT_TERMINE_BUCHEN = 18,
	TRAININGSPLAN_VORLAGEN_VERWALTEN = 19,
	PATIENT_LOESCHEN = 20,
	VIDEOS_BEARBEITEN = 21,
	STATISTIK_SCHREIBEN = 22,
	STATISTIK_LESEN = 23
}

export default {
	numberToString(privilege: Privilege): string {
		return Privilege[privilege];
	},
	stringToNumber(str: string): Privilege {
		const str2 = str.toUpperCase() as keyof typeof Privilege;
		return Privilege[str2];
	},
	numbersToStrings(privileges: Privilege[]): string[] {
		const strings: string[] = [];
		for (const privilege of privileges) {
			strings.push(this.numberToString(privilege));
		}
		return strings;
	},
	stringsToNumbers(strings: string[]): Privilege[] {
		const privileges: Privilege[] = [];
		for (const str of strings) {
			privileges.push(this.stringToNumber(str));
		}
		return privileges;
	},
	allValues(): string[] {
		const enumValues: Array<string> = [];
		for (const value in Privilege) {
			if (typeof Privilege[value] === "number") {
				enumValues.push(value);
			}
		}
		return enumValues;
	},
};
