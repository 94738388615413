




















































import Axios from "axios";
import { mapMutations } from "vuex";
import api from "../../config/api";
export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    dokumente: {
      type: Array,
      default: null,
    },
    authority: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    dokumentModel: "",
    dialogEditDokument: false,
    displayedDokumente: [],
  }),
  created() {
    this.displayedDokumente = this.dokumente;
  },
  watch: {
    dokumente(newVal: any) {
      this.displayedDokumente = newVal;
    },
  },
  computed: {
    ...mapMutations(["setNetworkError"])
  },
  methods: {
    formateDate(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleDateString();
    },
    formateTime(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleTimeString();
    },
    editDokumentSave() {
      this.dialogEditDokument = false;
      let requestUrl;
      if (this.authority === "therapeut") requestUrl = "/dokumentation/" + this.info.patientId;
      else requestUrl = "/dokumentation/betreiber/" + this.info.patientId;
      Axios.post(requestUrl, this.dokumentModel, api.getRequestConfig())
      .then(response => {
        this.$emit("dataChanged");
        this.dokumentModel = null;
      })
      .catch((error: Error) => {
        
        this.$store.commit("setNetworkError");
      })
    },
    editDokumentDiscard() {
      this.dokumentModel = null;
      this.dialogEditDokument = false;
      },
    editDokumentBack() {
      this.dialogEditDokument = false;
    },
  }
}
