import Vue from "vue";
import VueHead from 'vue-head';
import vueVimeoPlayer from 'vue-vimeo-player';
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import { store } from "./store";

Vue.config.productionTip = false;
Vue.use(VueHead, {
  separator: '-',
  complement: process.env.VUE_APP_PAGE_TITLE_PREFIX
})
Vue.use(vueVimeoPlayer)

new Vue({
  vuetify,
  router,
  store: store,
  render: h => h(App),
}).$mount("#app")
