import Api from "@/config/api";
import axios from "axios";

export default {
  getPatientFragebogenList() {
    const url = "/fragebogen/patient/all";
    return axios
      .get(url, Api.getRequestConfig())
      .then(response => {
        return response.data;
      })
      .catch(error => {
				
        console.log(error);
        return null
      });
  },
  getPatientFragebogen(patientId:number) {
    const url = "/fragebogen/patient/get/" + patientId;
    return axios
      .get(url, Api.getRequestConfig())
      .then(response => {
        return response.data;
      })
      .catch(error => {
				
        console.log(error);
        return null
      });
  },  
  createNewPatientFragebogen(patientId:number) {
    const url = "/fragebogen/patient/new/" + patientId;
    return axios
      .get(url, Api.getRequestConfig())
      .then(response => {
        return response.data;
      })
      .catch(error => {
				
        console.log(error);
        return null
      });
  },
  savePatientFragebogen(fragebogen: any) {
    const url = "/fragebogen/patient/save";
    return axios
      .post(url, fragebogen, Api.getRequestConfig())
      .then(response => {
        return response.data;
      })
      .catch(error => {
				
        console.log(error);
        return null
      });
  },
  publishPatientFragebogen(fragebogen: any) {
    const url = "/fragebogen/patient/publish";
    return axios
      .post(url, fragebogen, Api.getRequestConfig())
      .then(response => {
        return response.data;
      })
      .catch(error => {
				
        console.log(error);
        return null
      });
  },

  getFragebogenList() {
    const url = "/fragebogen/all";
    return axios
      .get(url, Api.getRequestConfig())
      .then(response => {
        return response.data;
      })
      .catch(error => {
				
        console.log(error);
        return null
      });
  },
  createNewFragebogen() {
    const url = "/fragebogen/new";
    return axios
      .get(url, Api.getRequestConfig())
      .then(response => {
        return response.data;
      })
      .catch(error => {
				
        console.log(error);
        return null
      });
  },
  addFrage(frage: any) {
    const url = "/fragebogen/addFrage";
    return axios
      .post(url, frage, Api.getRequestConfig())
      .then(response => {
        return response.data;
      })
      .catch(error => {
				
        console.log(error);
        return null
      });
  },
  inactivateFrage(frage: any) {
    const url = "/fragebogen/inactivateFrage";
    return axios
      .post(url, frage, Api.getRequestConfig())
      .then(response => {
        return response.data;
      })
      .catch(error => {
				
        console.log(error);
        return null
      });
  },
  saveFragebogen(fragebogen: any) {
    const url = "/fragebogen/save";
    return axios
      .post(url, fragebogen, Api.getRequestConfig())
      .then(response => {
        return response.data;
      })
      .catch(error => {
				
        console.log(error);
        return null
      });
  },
  publishFragebogen(fragebogen: any) {
    const url = "/fragebogen/publish";
    return axios
      .post(url, fragebogen, Api.getRequestConfig())
      .then(response => {
        return response.data;
      })
      .catch(error => {
				
        console.log(error);
        return null
      });
  },
};
