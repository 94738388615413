



















































import Api from "@/config/api";
import { Privilege } from "@/data/user/Privilege";
import { Role } from "@/data/user/Role";
import VideoFullDto from "@/dtos/videos/VideoFullDto";
import VideoPatientDto from "@/dtos/videos/VideoPatientDto";
import Axios from "axios";
import { mapGetters } from "vuex";
import VideoAnzeige from "./VideoAnzeige.vue";
import VideoCreateDialog from "./VideoCreateDialog.vue";
import VideoListe from "./VideoListe.vue";

export default {
  components: {
    VideoListe,
    VideoAnzeige,
    VideoCreateDialog,
  },
  data() {
    return {
      videos: [],
      selectedVideo: undefined,
      adminView: false,
      therapeutView: false,
      canEdit: false,
      createDialog: false,
    };
  },
  created() {
    this.getVideos();
  },
  computed: {
    ...mapGetters(["isRole", "hasPrivilege"]),
  },
  methods: {
    updateVideoInArray(vid: VideoFullDto) {
      for (let i = 0; i < this.videos.length; i++) {
        if (this.videos[i].id === vid.id) {
          this.videos[i] = vid;
          this.selectedVideo = this.videos[i];
          break;
        }
      }
    },
    removeVideoSelected() {
      for (let i = 0; i < this.videos.length; i++) {
        if (this.videos[i].id === this.selectedVideo.id) {
          this.videos.splice(i, 1);
          this.selectedVideo = undefined;
          break;
        }
      }
    },
    handleCreatedVideo(vid: VideoFullDto) {
      this.videos.unshift(vid);
      this.createDialog = false;
    },
    selectVideo(vid: VideoFullDto | VideoPatientDto) {
      //console.log(vid);
      this.selectedVideo = vid;
    },
    getVideos() {
      if (this.checkPrivilege(Privilege.VIDEOS_BEARBEITEN)) {
        this.canEdit = true;
      }
      if (this.checkRole(Role.BETREIBER)) {
        this.adminView = true;
        this.restGet("video/betreiber");
      } else if (this.checkRole(Role.THERAPEUT)) {
        this.therapeutView = true;
        this.restGet("video/therapeut");
      } else {
        this.restGet("video/patient");
      }
    },
    restGet(url: string) {
      Axios.get(url, Api.getRequestConfig())
        .then((response) => {
          //console.log(response);
          this.videos = response.data;
        })
        .catch(() => {
				  
          console.log("Loading Failure.");
          this.canEdit = false;
          this.adminView = false;
          this.therapeutView = false;
          this.$store.state.error = {
            isVisible: true,
            headline: "Verbindungfehler zum Server!",
            /*okAction: function () {
              this.$store.state.error.isVisible = false;
            }*/
          }
        });
    },
    checkPrivilege(privilege: Privilege) {
      return this.$store.getters.hasPrivilege(privilege);
    },
    checkRole(role: Role) {
      return this.$store.getters.isRole(role);
    },
    getVideoInfo(videoUrl: string) {
      const url = "https://vimeo.com/api/oembed.json?url=" + videoUrl;
      return Axios.get(url)
        .catch(() => {
          
        });
    },
    openCreateDialog() {
      this.createDialog = true;
    },
  },
};
