











































import ProfilBenutzerDto from "@/data/ProfilBenutzerDto";
import Axios from "axios";
import { mapMutations } from "vuex";
import api from "../../config/api";
export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    editAddress: false,
    dialogEditAddress: false,
    straßeModel: null,
    hausnummerModel: null,
  }),
  created() {
    this.straßeModel = this.info.straße;
    this.hausnummerModel = this.info.hausnummer;
  },
  computed: {
    ...mapMutations(["setNetworkError"])
  },
  methods: {
    changeAddress() {
      if (!this.editAddress) {
        this.editAddress = true;
      } else {
        this.dialogEditAddress = true;
      }
    },
    editAddressSave() {
      this.info.straße = this.straßeModel;
      this.info.hausnummer = this.hausnummerModel;
      this.dialogEditAddress = false;
      this.editAddress = false;

      const profil: ProfilBenutzerDto = new ProfilBenutzerDto(
        this.info.vorname,
        this.info.nachname,
        "",
        "",
        this.info.hausnummer,
        this.info.straße,
        this.info.plz,
        this.info.ort,
        this.info.land,
        this.info.profilbild,
        this.info.ausbildung,
        this.info.schwerpunkte,
        this.info.beschreibung,
        this.info.geburtsdatum,
        this.info.telefon,
        this.info.listenanzeige
      );
      let requestUrl = "/profil/change";
      if (this.user !== null) {
        requestUrl = "/profil/other/" + this.user.id + "/betreiber";
      }
      Axios.post(requestUrl, profil, api.getRequestConfig())
      .catch((error: Error) => {
        
        this.$store.commit("setNetworkError");
      })
    },
    editAddressDiscard() {
      this.straßeModel = this.info.straße;
      this.hausnummerModel = this.info.hausnummer;
      this.dialogEditAddress = false;
      this.editAddress = false;
      },
    editAddressBack() {
      this.dialogEditAddress = false;
    },
  }
}
