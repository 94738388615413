import Api from "@/config/api";
import Uebung from "@/data/Uebung";
import axios from "axios";

export default {
  getAllUebungen() {
    const url: string =
      Api.getRequestConfig().baseURL + "/uebungen";
    return axios.get<[Uebung]>(url, {
      headers: Api.getRequestConfig().headers,
    })
		.catch(() => {
			
      return null
		});
  },
  getAllUebungenSortiertNachTitel() {
    const url: string =
      Api.getRequestConfig().baseURL + "/uebungen/sortiert/titel";
    return axios.get<[Uebung]>(url, {
      headers: Api.getRequestConfig().headers,
    })
		.catch(() => {
			
      return null
		});
  },
  getUebung(id: number) {
    const url: string =
      Api.getRequestConfig().baseURL + "/uebungen/" + id;
    return axios.get<Uebung>(url, {
      headers: Api.getRequestConfig().headers,
    })
		.catch(() => {
			
      return null
		});
  },
  storeUebung(uebung: Uebung) {
    const url: string =
      Api.getRequestConfig().baseURL + "/uebungen/save";
    return axios
      .post(url, uebung, { headers: Api.getRequestConfig().headers })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        
        return null
        console.log(error);
      });
  },
  deleteUebung(id: number) {
    const url: string =
      Api.getRequestConfig().baseURL + "/uebungen/betreiber/" + id;
    return axios
      .delete(url, { headers: Api.getRequestConfig().headers } )
      .then(response => {
        return response.data;
      })
      .catch(error => {
        
        return null
        throw(error);
      });
  },
  getUebungenByIds(idString: string) {
    const url: string =
      Api.getRequestConfig().baseURL + "/uebungen/ids/" + idString;
    return axios
      .get<Uebung[]>(url, { headers: Api.getRequestConfig().headers } )
      .then(response => {
        return response.data;
      })
      .catch(error => {
                
        console.log(error);
        return null
      });
  },
  getVideoInfo(videoUrl: string) {
      const url = "https://vimeo.com/api/oembed.json?url=" + videoUrl;
      return axios.get(url)
      .catch(() => {
        
        return null
      });
  }
};
