


























import Api from "@/config/api";
import urlHelper from "@/helper/urlDto";
import DokumentApi from "@/services/api/Dokument";
import axios from "axios";

export default {
  data: () => ({
    aboGuthaben: 0,
    freiesGuthaben: 0,
    cartId: undefined,
    redirectUrl: undefined,
    cart: undefined,
    hasUnpaid: false,
  }),
  methods: {
    greeting() {
      return (
        "Hallo " +
        this.cart.vorname +
        " " +
        this.cart.nachname
      ).toUpperCase();
    },
    getPaidStatus(itemId: number) {
      let item = this.cart.items[itemId];
      return item.status === "PAID";
    },
    getText(itemId: number) {
      let item = this.cart.items[itemId];
      let text = ">" + item.terminName + "<";
      let paymentMethod =
        item.paymentMethod === "MOLLIE"
          ? " bezahlt."
          : " mit " + item.paymentMethod + " bezahlt.";
      text +=
        item.status === "PAID"
          ? " wurde" + paymentMethod
          : " wurde nicht bezahlt.";
      return text;
    },
    getCart(cartId: number) {
      const url = "/cart/get/" + cartId;
      axios.get(url, Api.getRequestConfig()).then(response => {
        console.log(response.data);
        this.cart = response.data;
        for (let cartItem of response.data.items) {
          if (cartItem.status !== "PAID") {
            this.hasUnpaid = true;
          }
        }
        if (this.hasUnpaid) {
          this.redirectUrl = response.data.redirectUrl;
        }
      })
      .catch(() => {
				
      });
    },
    redirect: function() {
      this.$router.push(urlHelper.getUrl(this.redirectUrl)).catch(() => {});
    },
    printBuchungsbestätigung() {
      DokumentApi.getTerminbuchungsBestaetigung(this.cartId);
    },
    backToShop() {
      this.$router
        .push({
          name: "Patient: Termine",
        })
        .catch(() => {});
    },
  },
  created() {
    this.cartId = this.$route.params.id;
    this.getCart(this.$route.params.id);
  },
};
