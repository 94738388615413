import MemberObject from "@/data/videotherapie/MemberObject";
import DisconnectMessage from "@/data/videotherapie/messages/outgoing/DisconnectMessage";
import JoinConferenceMessage from "@/data/videotherapie/messages/outgoing/JoinConferenceMessage";
import PublishSdpResponseMessage from "@/data/videotherapie/messages/outgoing/PublishSdpResponseMessage";
import PublishStreamMessage from "@/data/videotherapie/messages/outgoing/PublishStreamMessage";
import SubscribeSdpResponseMessage from "@/data/videotherapie/messages/outgoing/SubscribeSdpResponseMessage";
import SubscribeStreamMessage from "@/data/videotherapie/messages/outgoing/SubscribeStreamMessage";
import WebsocketMessageOutgoing from "@/data/videotherapie/messages/outgoing/WebsocketMessageOutgoing";
import StreamObject from "@/data/videotherapie/StreamObject";
import { Client, StompConfig } from "@stomp/stompjs";
import api from "../../config/api";
import WebRtc from "./WebRtc";

export default class Websocket {
    private static instance: Websocket | undefined = undefined
    private conferenceId:string|undefined = undefined
    private errorCount:number = 0
    private stompClient: Client|undefined
    private isLogging:boolean = true
    private testStep:string = ""
    private sessionId:string|undefined
    private subscribed:boolean=false
    private bearerToken:string|undefined
    private connected:boolean=false
    private handleMessage:(message:any /*TODO:!!!*/) => void = (message:any) => {}
    private refresher: (() => void) | undefined 
    private store : any;
    
    public static getInstance(store?:any){        
        if(Websocket.instance===undefined){
            Websocket.instance=new Websocket()
            if(!store){
                console.error("Fehlermeldungen aus dem WebsocketSingleton werden nicht funktionieren, weil der state nicht an getInstance übergeben wurde und somit nicht aufrufbar ist.")
            }
        }
        if(store && this.instance){
            this.instance.store = store
        }        
        return Websocket.instance
    }

    public connect(conferenceId:string, sessionId: string, bearerToken:string, handleMessage: (message:any /*TODO:!!!*/) => void, foreignStreamsCount:number, startedCallback?:()=>void){
        this.sessionId = sessionId
        this.bearerToken=bearerToken
        WebRtc.setForeignStreamsCount(foreignStreamsCount)
        this.handleMessage = handleMessage        
        if(this.conferenceId!==undefined){           
            this.disconnect()
            this.connect(conferenceId, sessionId,bearerToken, handleMessage, foreignStreamsCount, startedCallback)
        }
        else{
            //connect here
            const stompConfig:StompConfig = {brokerURL: api.getWebsocketBackendURL(),
                                             reconnectDelay: 5000, heartbeatIncoming:4000, heartbeatOutgoing: 4000}
            this.stompClient = new Client(stompConfig);
            this.stompClient.onConnect = () => {
                                                    
                                                    // Hier wird die UserId als SessionId genutzt.
                                                    // Das ist vielleicht suboptimal, da hier ein Kommentar dazu stand - K
                                                    console.log("SessionId: ", sessionId)
                                                    this.stompClient?.subscribe(
                                                        "/topic/private/" + sessionId,
                                                        (message) => {
                                                            if (this.subscribed !== null) this.subscribed = true;
                                                            this.handleMessage(JSON.parse(message.body));
                                                        },
                                                        { bearer: this.bearerToken??"" }
                                                    );
                                                    this.connected = true;
                                                    this.errorCount = 0;
                                                    if (this.isLogging !== null) {
                                                        this.testStep += "<br>Baue Kommunikationkanal auf"; 
                                                        this.refreshView()
                                                    }                                                   
                                                    console.debug("subscribed to "+"/topic/private/" + sessionId)
                                                    this.startSession(startedCallback)
                                                }
            
            if(this.isLogging) {
                this.testStep += "<br>Baue Verbindung zum Server auf";
                this.refreshView()
            }

            this.stompClient.activate()           

        }
        this.conferenceId=conferenceId
    }

    public getSessionId(){
        return this.sessionId
    }

    public getConferenceId(){
        return this.conferenceId
    }

    public isSubscribed(){
        return this.subscribed
    }

    public isConnected(){
        return this.connected
    }

    public getTestStep(){
        return this.testStep;
    }

    public disconnect(){
        WebRtc.getInstance().disconnect(() => this.refreshView())
        this.sendMessage(new DisconnectMessage(""))
        this.stompClient?.deactivate()
        this.connected=false;
        this.stompClient=undefined
        this.conferenceId=undefined
        //Websocket.instance = undefined
        this.testStep=""
        this.refreshView();
    }

    public setRefreshView(callback: () => void){
        this.refresher=callback
    }

    private refreshView(){
        if(this.refresher){
           this.refresher() 
        }
        
    }

    private startSession(startedCallback?:()=>void){
        console.debug("Starting session")
        if (startedCallback!==undefined) startedCallback();
			if (this.isLogging) {
                this.testStep += "<br>Starte Sitzung zum Verbindungstest";
                this.refreshView();
            }
			//DEBUGGING: This join may not work as intended
			if (this.conferenceId !== null && this.conferenceId !== undefined) {
                console.debug("Trying to join conference")
				this.sendMessage(new JoinConferenceMessage("", this.conferenceId));
				// TODO Patient?: context.joining = true;
				/* OBSOLETE?: context.pingTimeoutId = window.setInterval(function (scope: any, context: any) {
					scope.sendPing(context);
				}, 120000, this, context);*/
			}
            else{
                console.debug("ConferenceId is: "+this.conferenceId)
            }

    }

    public publishStream() {
        if(WebRtc.getInstance().getLocalCameraStream()===undefined)	{	     
            navigator.mediaDevices.getUserMedia({ audio: true, video: true })
                .then(function getUserMediaSuccess(stream) {
                    
                    WebRtc.getInstance().setLocalCameraStream(stream);
                    if (Websocket.getInstance().isLogging){
                        Websocket.getInstance().testStep += "<br>Füge eigenen Stream in Seite ein";
                        Websocket.getInstance().testStep += "<br>Sende eigenes Video";
                        Websocket.getInstance().refreshView()
                    } 
                    Websocket.getInstance().sendMessage(new PublishStreamMessage("", "camera", true, true));
                })
                .catch(function getUserMediaError(error) { 
					console.debug("Fehler in getUserMedia: ",error)
                    if(Websocket.instance){
                        Websocket.instance.store.state.error = {
                            isVisible: true,
                            headline: "Fehler beim Zugriff auf die Kamera",
                            text: 'Der Zugriff auf die Kamera ist nicht möglich. \n\nBitte schließen Sie alle Apps, die die Kamera nutzen. Starten Sie dann den Test erneut.',
                            okAction: () => {
                            window.history.back()
                            }
                        }
                    }
                });
        }
        else{            
            if (this.isLogging){
                this.testStep += "<br>Füge eigenen Stream in Seite ein";
                this.testStep += "<br>Sende eigenes Video";
                this.refreshView();  
            } 
            this.sendMessage(new PublishStreamMessage("", "camera", true, true));
        }
	}

    private sendMessage(msg: WebsocketMessageOutgoing) {
		msg.bearer = this.bearerToken??""
		this.stompClient?.publish({destination: "/app/sendOnly", body:JSON.stringify(msg)});
	}

    public handlePublishSdpRequest(sdp: string, stream: StreamObject) {
		// the backend sent an SDP offer for publishing our local stream
        const localPc = WebRtc.getInstance().getLocalPc()
        if(localPc !== undefined && localPc!==null){
            localPc.setRemoteDescription({ type: "offer", sdp: sdp })
            .then(function setRemoteOk() {
                const localCameraStream = WebRtc.getInstance().getLocalCameraStream()
                if(localCameraStream!==undefined){
                    for (const track of localCameraStream.getTracks()) {
                        localPc.addTrack(track);
                    }
                    localPc.createAnswer()
                    .then(function createAnswerOk(description: any) {
                        return localPc.setLocalDescription(description);
                    })
                    .then(function setLocalOk(description: any) {
                        if(localPc.currentLocalDescription){
                            Websocket.getInstance().sendMessage(new PublishSdpResponseMessage("", localPc.currentLocalDescription.sdp,stream.rxRtpEndpointId,stream.streamUuid));
                        }                        
                    });
                }  
            });
        }
        
	}

    public handleStreamStatusChange(
		stream: StreamObject,
		active: boolean,
		member: MemberObject
	) {
		// the status of a stream changed
		if (active) {
			this.addStreamVideoElement(stream, member);
			if (this.isLogging){
                this.testStep += "<br>Stelle Anfrage, eigenen Stream zu erhalten";
                this.refreshView()
            } 
			this.sendMessage(new SubscribeStreamMessage("", stream.name, stream, true, true));
			// TODO: remove stream.name as it is part of stream (redundant)
		} else {
			// Die folgende Methode fehlte und war hier als 'to do' markiert.
			// Ich kann nicht sagen ob die wirklich notwendig ist. - K
			this.removeStreamVideoElement(stream, member, active);
		}
        this.refreshView()
	}

    public handleSubscribeSdpRequest(
		sdp: string,
		stream: StreamObject,
		txRtpEndpointId: string
	) {
		// the backend sent an SDP offer for receiving a remote stream
		const pc = new RTCPeerConnection();
        WebRtc.getInstance().addPeerConnection(pc)
		
		pc.ontrack = function (event) {
            console.debug("pc.ontrack")
            const foreignStreams = WebRtc.getInstance().getForeignStreams()
            if(foreignStreams){
                console.debug("foreignStreams")
                console.debug(foreignStreams)
                for (const streamObject of foreignStreams) {
                    console.debug("streamObject")
                    console.debug(streamObject)
                    if (
                        streamObject.stream !== null && streamObject.stream !== undefined &&
                        streamObject.stream.streamUuid == stream.streamUuid
                    ) {
                        console.log("setting video of streamobject")
                        console.log("index of streamobject:" + foreignStreams.indexOf(streamObject))
                        console.debug("there are "+event.streams.length+" streams in the track")
                        streamObject.video = event.streams[0];
                        streamObject.show = false; 
                        console.debug("Trying to refresh view from pc.ontrack")                       
                        Websocket.getInstance().refreshView()
                        break;
                    }
                }
            }
            else{
                console.debug("no foreignStreams")
            }			
		};
		pc.setRemoteDescription(
			new RTCSessionDescription({ type: "offer", sdp: sdp })
		).then(function () {
			pc.createAnswer().then(function (description) {
				return pc.setLocalDescription(description).then(function () {
					if (Websocket.getInstance().isLogging){
                        Websocket.getInstance().testStep += "<br>Anfrage genehmigt"; 
                        Websocket.getInstance().refreshView()
                    } 
					Websocket.getInstance().sendMessage(new SubscribeSdpResponseMessage("", String(description.sdp), txRtpEndpointId, stream, stream.name));
					if (Websocket.getInstance().isLogging){
                        Websocket.getInstance().testStep += "<br>Technischer Test erfolgreich";
                        Websocket.getInstance().refreshView()
                    } 
					//TODO: !!! if (context.testRunning !== null) context.testRunning = false;
				});
			});
		});
	}

    private addStreamVideoElement(
		stream: StreamObject,
		member: MemberObject
	) {
		let counter: number = 0
		for (const streamObject of WebRtc.getInstance().getForeignStreams()) {
			if (streamObject.stream ===undefined|| streamObject.name === member.memberUuid) {
				streamObject.stream = stream;
				streamObject.name = member.memberUuid;
				/*TODO: !!! 
                if (context.hasOwnProperty('patientUsers')) {
					context.patientUsers[counter] = member.displayName;
				}
				if (context.hasOwnProperty('patientIds')) {
					context.patientIds[counter] = member.memberUuid;
				}*/
				break;
			}
			counter++;
		}
	}

    private removeStreamVideoElement(
		stream: StreamObject,
		member: MemberObject,
		active: boolean
	) {
		/*TODO: !!!
        console.log("RemoveStreamVideoElement Method called for memeber: " + member.displayName);
		if (context.hasOwnProperty('otherStreams')) {
			for (const streamObject of context.otherStreams) {
				if (streamObject.stream === null || streamObject.name === member.memberUuid) {
					console.log("Found corresponding Stream for Member with UUID: " + member.memberUuid);
					var localStream = streamObject.stream;
					localStream.audio = stream.audio;
					localStream.video = stream.video;
					localStream.active = active;
					if (!active) {
						if (localStream.pc) {
							try {
								console.log("Attempting to close the peer connection of this Stream...")
								localStream.pc.close();
								localStream.pc = null;
							} catch (error) {
								console.log("something went wrong closing the Stream.")
							}
						}
						delete context.otherStreams[stream.name];
					}
				}
			}
		}*/        
	}
}