



































import api from "@/config/api";
import ProfilBenutzerDto from "@/data/ProfilBenutzerDto";
import Axios from "axios";
import UploadFiles from "../general/Upload/UploadFiles.vue";
export default {
  components: {
    UploadFiles,
  },
  props: {
    profilePhoto: {
      type: String,
      default: null,
    },
    myProfil: {
      type: Boolean,
      default: null,
    },
    info: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    editImg: false,
    dialogEditImg: false,
    save: false,
    uploadPath: "/upload/profile",
  }),
  created() {
    if (this.user) {
      this.uploadPath += "/" + this.user.id;
    }
  },
  methods: {
    changeImg() {
      if (!this.editImg) {
        this.editImg = true;
      } else {
        this.dialogEditImg = true;
      }
    },
    editImgSave() {
      this.info.profilbild = this.user
        ? this.user.id
        : this.$store.getters.authentication.userId;
      this.dialogEditImg = false;
      this.editImg = false;

      const profil: ProfilBenutzerDto = new ProfilBenutzerDto(
        this.info.vorname,
        this.info.nachname,
        "",
        "",
        this.info.hausnummer,
        this.info.straße,
        this.info.plz,
        this.info.ort,
        this.info.land,
        this.info.profilbild,
        this.info.ausbildung,
        this.info.schwerpunkte,
        this.info.beschreibung,
        this.info.geburtsdatum,
        this.info.telefon,
        this.info.listenanzeige
      );
      let requestUrl = "/profil/change";
      if (this.user !== null) {
        requestUrl = "/profil/other/" + this.user.id + "/betreiber";
      }
      Axios.post(requestUrl, profil, api.getRequestConfig())
        .then(() => {
          this.save = true;
        })
        .catch((error: Error) => {
          
          this.$store.commit("setNetworkError");
        });
    },
    editImgDiscard() {
      this.dialogEditImg = false;
      this.editImg = false;
    },
  },
};
