import Api from "@/config/api";
import axios from "axios";

export default {
    getMostRecent() {
        const url: string =
          Api.getRequestConfig().baseURL + "/payment/mostrecent/";
          const headers = Api.getRequestConfig().headers;      
        return axios
          .get(url, { headers: headers })
          .then(response => { 

            return response.data;
          })
          .catch(error => {
            
            console.log(error);
            return null
          });
    },  
    
    createSample(description:string, amount:number) {
        const url: string =
            Api.getRequestConfig().baseURL + "/payment/sample/";
            const headers = Api.getRequestConfig().headers;      
        return axios
            .post(url, {description: description, amount: amount}, { headers: headers })
            .then(response => {      
            return response.data;
            })
            .catch(error => {
              
              console.log(error);
              return null
            });
    },  
};
