



































import ProfilBenutzerDto from "@/data/ProfilBenutzerDto";
import Axios from "axios";
import { mapMutations } from "vuex";
import api from "../../config/api";
export default {
  props: {
    info: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    editCountry: false,
    dialogEditCountry: false,
    countryModel: null,
  }),
  created() {
    this.countryModel = this.info.land;
  },
  computed: {
    ...mapMutations(["setNetworkError"])
  },
  methods: {
    changeCountry() {
      if (!this.editCountry) {
        this.editCountry = true;
      } else {
        this.dialogEditCountry = true;
      }
    },
    editCountrySave() {
      this.info.land = this.countryModel;
      this.dialogEditCountry = false;
      this.editCountry = false;

      const profil: ProfilBenutzerDto = new ProfilBenutzerDto(
        this.info.vorname,
        this.info.nachname,
        "",
        "",
        this.info.hausnummer,
        this.info.straße,
        this.info.plz,
        this.info.ort,
        this.info.land,
        this.info.profilbild,
        this.info.ausbildung,
        this.info.schwerpunkte,
        this.info.beschreibung,
        this.info.geburtsdatum,
        this.info.telefon,
        this.info.listenanzeige
      );
      let requestUrl = "/profil/change";
      if (this.user !== null) {
        requestUrl = "/profil/other/" + this.user.id + "/betreiber";
      }
      Axios.post(requestUrl, profil, api.getRequestConfig())
      .catch((error: Error) => {
        
        this.$store.commit("setNetworkError");
      })
    },
    editCountryDiscard() {
      this.countryModel = this.info.land;
      this.dialogEditCountry = false;
      this.editCountry = false;
    },
    editCountryBack() {
      this.dialogEditCountry = false;
    },
  }
}
