



















































import Api from "@/config/api";
import { ProfilViewType } from "@/data/ProfilViewType";
import { Role } from "@/data/user/Role";
import { BenutzerProfilDto } from "@/dtos/BenutzerProfilDto";
import { OtherProfilBetreiberDto } from "@/dtos/OtherProfilBetreiberDto";
import { PatientProfilTherapeutDto } from "@/dtos/PatientProfilTherapeutDto";
import { TherapeutProfilPatientDto } from "@/dtos/TherapeutProfilPatientDto";
import { UserDto } from "@/dtos/UserDto";
import axios from "axios";
import { mapGetters } from "vuex";
import BetreiberProfil from "./BetreiberProfil.vue";
import PatientProfil from "./PatientProfil.vue";
import PatientProfilAnsichtTherapeut from "./PatientProfilAnsichtTherapeut.vue";
import TherapeutProfil from "./TherapeutProfil.vue";
import TherapeutProfilAnsichtPatient from "./TherapeutProfilAnsichtPatient.vue";

interface Data {
  user: UserDto | null;
  info:
    | BenutzerProfilDto
    | TherapeutProfilPatientDto
    | PatientProfilTherapeutDto
    | null;
  dokumente: any | null;
  getSuccess: boolean;
  profilViewType: ProfilViewType | null;
  patientId: number | undefined;
  userId: number | undefined;
}

export default {
  components: {
    PatientProfil,
    TherapeutProfil,
    BetreiberProfil,
    TherapeutProfilAnsichtPatient,
    PatientProfilAnsichtTherapeut,
  },
  props: {
    userIdProp: {
      type: Number,
      default: undefined,
    },
  },
  data(): Data {
    return {
      user: null,
      info: null,
      getSuccess: false,
      profilViewType: null,
      dokumente: null,
      patientId: undefined,
      userId: undefined,
    };
  },
  created() {
    this.selectProfilType();
  },
  computed: {
    /* Gib an, ob das Profil durch die Person selber oder einen anderen aufgerufen wurde */
    myProfil(): boolean {
      return this.profilViewType === ProfilViewType.MY_PROFIL;
    },
    /* Wurde das Profil durch eine andere Person aufgerufen und gehört es einem Betreiber? */
    otherBetreiberProfil(): boolean {
      return (
        this.profilViewType !== ProfilViewType.MY_PROFIL &&
        this.user !== null &&
        Role.BETREIBER === this.user.role
      );
    },
    /* Wurde das Profil durch eine andere Person aufgerufen und gehört es einem Therapeuten? */
    otherTherapeutProfil(): boolean {
      return (
        this.profilViewType !== ProfilViewType.MY_PROFIL &&
        ((this.user !== null && Role.THERAPEUT === this.user.role) ||
          (this.info !== null && !this.info.patient))
      );
    },
    /* Wurde das Profil durch eine andere Person aufgerufen und gehört es einem Patienten? */
    otherPatientProfil(): boolean {
      return (
        this.profilViewType !== ProfilViewType.MY_PROFIL &&
        ((this.user !== null && Role.PATIENT === this.user.role) ||
          (this.info !== null && this.info.patient))
      );
    },
    ...mapGetters(["isRole"]),
  },
  watch: {
    $route() {
      this.getSuccess = false;
      this.selectProfilType();
    },
    userIdProp() {
      this.getSuccess = false;
      this.selectProfilType();
    },
  },
  methods: {
    selectProfilType() {
      this.userId = this.$route.params.userId;
      if (this.userIdProp !== undefined) {
        this.userId = this.userIdProp;
      }
      if (this.userId === undefined) {
        this.profilViewType = ProfilViewType.MY_PROFIL;
        this.getMyProfil();
      } else if (this.isRole(Role.BETREIBER)) {
        this.profilViewType = ProfilViewType.BETREIBER_VIEW;
        this.getOtherProfilBetreiber();
      } else if (this.isRole(Role.THERAPEUT)) {
        this.profilViewType = ProfilViewType.THERAPEUTEN_VIEW;
        this.getOtherProfilTherapeut();
      } else if (this.isRole(Role.PATIENT)) {
        this.profilViewType = ProfilViewType.PATIENTEN_VIEW;
        this.getOtherProfilPatient();
      }
    },
    getOtherProfilTherapeut() {
      const url: string = "/profil/other/" + this.userId + "/therapeut";
      axios
        .get<PatientProfilTherapeutDto>(url, Api.getRequestConfig())
        .then((response) => {
          this.info = response.data;
          if (this.info.patient) {
            this.patientId = response.data.patientId;
          }
          axios
            .get(
              "/dokumentation/" + this.info.patientId,
              Api.getRequestConfig()
            )
            .then((response) => {
              this.dokumente = response.data;
              axios
                .get(
                  "/dokumentation/betreiber/" + this.info.patientId,
                  Api.getRequestConfig()
                )
                .then((response) => {
                  this.dokumente = this.dokumente.concat(response.data);
                  this.dokumente.sort(function (a: any, b: any) {
                    return (
                      new Date(a.datum).getTime() - new Date(b.datum).getTime()
                    );
                  });
                  this.getSuccess = true;
                });
            });
        })
        .catch((error) => {
          
          console.log(error);
        });
    },
    getOtherProfilPatient() {
      const url: string = "/profil/other/" + this.userId + "/patient";
      axios
        .get<TherapeutProfilPatientDto>(url, Api.getRequestConfig())
        .then((response) => {
          this.info = response.data;
          this.getSuccess = true;
        })
        .catch((error) => {
          
          console.log(error);
        });
    },
    getOtherProfilBetreiber() {
      const url: string = "/profil/other/" + this.userId + "/betreiber";
      axios
        .get<OtherProfilBetreiberDto>(url, Api.getRequestConfig())
        .then((response) => {
          this.info = response.data.benutzerProfil;
          this.user = response.data;
          this.getSuccess = true;
          axios
            .get(
              "/dokumentation/" + this.info.patientId,
              Api.getRequestConfig()
            )
            .then((response) => {
              this.dokumente = response.data;
              axios
                .get(
                  "/dokumentation/betreiber/" + this.info.patientId,
                  Api.getRequestConfig()
                )
                .then((response) => {
                  this.dokumente = this.dokumente.concat(response.data);
                  this.dokumente.sort(function (a: any, b: any) {
                    return (
                      new Date(a.datum).getTime() - new Date(b.datum).getTime()
                    );
                  });
                  this.getSuccess = true;
                });
            });
        })
        .catch((error) => {
          
          console.log(error);
        });
    },
    getMyProfil() {
      axios
        .get<BenutzerProfilDto>("/profil/my", Api.getRequestConfig())
        .then((response) => {
          this.info = response.data;
          this.getSuccess = true;
        })
        .catch((error) => {
          
          console.log(error);
        });
    },
    dataChanged() {
      this.selectProfilType();
    },
  },
};
