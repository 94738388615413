






























































































































































































































































































import Api from "@/config/api";
import Therapeut from "@/data/Therapeut";
import GuthabenDto from "@/dtos/GuthabenDto";
import DokumentApi from "@/services/api/Dokument";
import PatientApi from "@/services/api/Patient";
import TerminApi from "@/services/api/Termin";
import { Termintyp } from "@/services/api/Termintyp";
import axios from "axios";
import { addYears, formatISO } from "date-fns";
import { mapMutations } from "vuex";
import PatientTerminAboBalken from "./PatientTerminAboBalken.vue";
import PatientTerminExpansionPanels from "./PatientTerminExpansionPanels.vue";
import PatientTerminGuthabenBalken from "./PatientTerminGuthabenBalken.vue";

export default {
  components: {
    PatientTerminExpansionPanels,
    PatientTerminGuthabenBalken,
    PatientTerminAboBalken,
  },
  data: () => ({
    terminTypEinzel: Termintyp[Termintyp.EINZEL],
    terminTypGruppe: Termintyp[Termintyp.GRUPPE],
    terminTypTelefon: Termintyp[Termintyp.TELEFON],
    terminTypAnamnese: Termintyp[Termintyp.ANAMNESE],
    terminTypBefund: Termintyp[Termintyp.BEFUND],
    termine: [],
    user: { vorname: "", nachname: "" },
    gefilterteTermine: [],
    selectedTermin: null,
    filterTerminart: "frei",
    einzeltermine: true,
    gruppentermine: true,
    aboEinzelTermin: 0,
    aboGruppenTermin: 0,
    aboBefundTermin: 0,
    aboTrainingsplan: 0,
    bezahltEinzelTermin: 0,
    bezahltGruppenTermin: 0,
    bezahltBefundTermin: 0,
    bezahltTelefonTermin: 0,
    bezahltAnamneseTermin: 0,
    telefontermine: true,
    befundtermine: true,
    filterBefund: "",
    filterTherapeut: "",
    zeitraumMenu: false,
    zeitraum: [],
    tempZeitraum: [],
    urlId: undefined,
    today: new Date(Date.now()),
    panels: [],
    cart: [],
    checkbox: false,
    todayString: "",
    todayInOneYearString: "",
    terminBearbeiten: false,
    payConfirmation: false,
    paymentLoading: false,
    cartId: undefined,
    finalCart: { items: [] },
    //new
    patientId: 0,
  }),
  created() {
    if (this.$route.params.patient) {
      this.patientId = this.$route.params.patient;
      this.$store.commit("selectedPatientId", this.patientId);
    } else {
      this.patientId = this.$store.state.selectedPatientId;
    }
    this.getData();
    this.getGuthaben();
  },
  watch: {
    termine() {
      this.updateFilter();
    },
    filterTerminart() {
      this.updateFilter();
    },
    einzeltermine() {
      this.updateFilter();
    },
    telefontermine() {
      this.updateFilter();
    },
    gruppentermine() {
      this.updateFilter();
    },
    zeitraum() {
      this.updateFilter();
    },
    filterBefund() {
      this.updateFilter();
    },
    filterTherapeut() {
      this.updateFilter();
    },
  },
  computed: {
    displayedZeitraum: {
      get() {
        if (!this.zeitraum) {
          return "";
        } else {
          const dateArray: string[] = [];
          for (const date of this.zeitraum) {
            dateArray.push(this.formatDate(date));
          }
          if (dateArray.length === 1) return dateArray[0];
          else return dateArray[0] + " - " + dateArray[1];
        }
      },
      set(newZeitraum: any) {
        this.zeitraum = newZeitraum;
      },
    },
    ...mapMutations(["filterSelectedTermine"]),
    ...mapMutations(["urlSelectedTermine"]),
    ...mapMutations(["selectedPatientId"]),
  },
  methods: {
    getGuthaben: function () {
      const url = "/guthaben/betreiber/get/" + this.patientId;
      return axios
        .get<GuthabenDto>(url, Api.getRequestConfig())
        .then((response) => {
          this.aboEinzelTermin = response.data.aboEinzelTermin;
          this.aboGruppenTermin = response.data.aboGruppenTermin;
          this.aboBefundTermin = response.data.aboBefundTermin;
          this.aboTrainingsplan = response.data.aboTrainingsplan;
          this.bezahltAnamneseTermin = response.data.bezahltAnamneseTermin;
          this.bezahltBefundTermin = response.data.bezahltBefundTermin;
          this.bezahltEinzelTermin = response.data.bezahltEinzelTermin;
          this.bezahltGruppenTermin = response.data.bezahltGruppenTermin;
          this.bezahltTelefonTermin = response.data.bezahltTelefonTermin;
        })
        .catch((error) => {
          
          console.log(error);
        });
    },
    buchungsDatum(date: string) {
      if (date == "") return "";
      return new Date(date).toLocaleDateString();
    },
    getData() {
      if (this.zeitraum == null || this.zeitraum.length === 0) {
        this.todayString = formatISO(this.today, { representation: "date" });
        this.todayInOneYearString = formatISO(addYears(this.today, 1), {
          representation: "date",
        });
        this.displayedZeitraum = [this.todayString, this.todayInOneYearString];
      }
      TerminApi.getPatientenTerminListForPatient(this.patientId).then(
        (response) => {
          this.termine = response?.data;
          PatientApi.getPatientByPatientId(this.patientId).then((response) => {
            this.user.vorname = response?.data.vorname;
            this.user.nachname = response?.data.nachname;
            this.gefilterteTermine = this.termine;
            this.updateFilter();
          });
        }
      );
    },
    cancelPayment(cartId: number) {
      console.log("Not yet implemented: CANCEL PAYMENT");
      /* NOT YET IMPLEMENTED
      const url = "/cart/patient/cancelPayment/"+cartId;
      axios.get(url, Api.getRequestConfig()).then(() => {
        this.getData();
        this.getGuthaben();
			});
			*/
    },
    addToCart(termin: any) {
      if (!this.cart.includes(termin)) {
        this.cart.push(termin);
      }
      this.checkbox = true;
      for (const termin of this.gefilterteTermine) {
        if (!termin.angemeldet && !this.cart.includes(termin)) {
          this.checkbox = false;
        }
      }
    },
    addAllToCart() {
      for (const termin of this.gefilterteTermine) {
        if (!this.cart.includes(termin) && !termin.angemeldet) {
          this.cart.push(termin);
        }
      }
    },
    removeFromCart(termin: any) {
      if (this.cart.includes(termin)) {
        this.cart.pop(termin);
      }
      const tempAll = [];
      for (const termin of this.gefilterteTermine) {
        if (!termin.angemeldet) {
          tempAll.push(termin);
        }
      }
      if (this.cart.length < tempAll.length) {
        this.checkbox = false;
      }
    },
    removeAllFromCart() {
      this.cart = [];
    },
    toggleBearbeiten() {
      this.terminBearbeiten = false;
    },
    updateFilter() {
      this.cart = [];
      this.gefilterteTermine = this.termine;
      if (this.filterTerminart === "url") {
        this.gefilterteTermine = this.gefilterteTermine.filter(
          (termin: any) => {
            if (termin.id === this.urlId) {
              this.$store.commit("urlSelectedTermine", termin);
            }
            return termin.id === this.urlId;
          }
        );
      } else {
        if (this.filterTerminart === "frei") {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              const terminDate = new Date(termin.datum);
              const now = new Date();
              return (
                termin.maxTeilnehmer > termin.anzahlTeilnehmer &&
                termin.angemeldet === false &&
                terminDate.getTime() > now.getTime()
              );
            }
          );
        } else if (this.filterTerminart === "angemeldet") {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return termin.angemeldet === true && termin.bezahlt === true;
            }
          );
        } else {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return (
                termin.angemeldet === false ||
                (termin.angemeldet === true && termin.bezahlt === true)
              );
            }
          );
        }
        if (!this.einzeltermine) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return termin.termintyp !== this.terminTypEinzel;
            }
          );
        }
        if (!this.gruppentermine) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return termin.termintyp !== this.terminTypGruppe;
            }
          );
        }
        if (!this.telefontermine) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return termin.termintyp !== this.terminTypTelefon;
            }
          );
        }
        if (!this.befundtermine) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return termin.termintyp !== this.terminTypBefund;
            }
          );
        }
        if (this.zeitraum) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              const terminDateString = termin.datum.substring(0, 10);
              if (this.zeitraum.length === 1) {
                return terminDateString === this.zeitraum[0];
              } else {
                return (
                  new Date(terminDateString) >= new Date(this.zeitraum[0]) &&
                  new Date(terminDateString) <= new Date(this.zeitraum[1])
                );
              }
            }
          );
        }
        if (this.filterBefund) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return termin.befund === this.filterBefund;
            }
          );
        }
        if (this.filterTherapeut) {
          this.gefilterteTermine = this.gefilterteTermine.filter(
            (termin: any) => {
              return (
                termin.therapeut.vorname + " " + termin.therapeut.nachname ===
                this.filterTherapeut
              );
            }
          );
        }
      }
      this.checkbox = false;
      this.$store.commit("filterSelectedTermine", this.gefilterteTermine);
      this.panels = this.getPanelIndizes();
    },
    selectionChanged(selectedItemIndex: number) {
      if (this.termine.length > selectedItemIndex && selectedItemIndex >= 0) {
        this.selectedTermin = this.termine[selectedItemIndex];
      }
    },
    getAlleBefunde() {
      const befunde: string[] = [];
      for (const termin of this.termine) {
        if (!befunde.some((b) => b === termin.befund)) {
          befunde.push(termin.befund);
        }
      }
      return befunde;
    },
    getAlleTherapeuten() {
      const therapeuten: Therapeut[] = [];
      const therapeutenNamen: string[] = [];
      for (const termin of this.termine) {
        if (!therapeuten.some((t) => t.id === termin.therapeut.id)) {
          therapeuten.push(termin.therapeut);
          therapeutenNamen.push(
            termin.therapeut.vorname + " " + termin.therapeut.nachname
          );
        }
      }
      return therapeutenNamen;
    },
    checkboxClickAction() {
      this.checkbox = !this.checkbox;
      if (this.checkbox) {
        this.addAllToCart();
      } else {
        this.removeAllFromCart();
      }
    },
    clearFilter() {
      this.displayedZeitraum = [this.todayString, this.todayInOneYearString];
      this.gruppentermine = true;
      this.einzeltermine = true;
      this.telefontermine = true;
      this.filterBefund = "";
      this.filterTherapeut = "";
      this.filterTerminart = "frei";
    },
    setZeitraum() {
      this.tempZeitraum.sort((a: string, b: string) => {
        return new Date(a) > new Date(b);
      });
      this.zeitraum = this.tempZeitraum;
      this.zeitraumMenu = false;
    },
    zeitraumAbbrechen() {
      this.zeitraumMenu = false;
      this.tempZeitraum = this.zeitraum;
    },
    formatDate(date: string) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    getPanelIndizes() {
      const indexArray: any[] = [];
      if (this.filterTerminart === "url") {
        indexArray.push(1);
      } else {
        for (const termin of this.gefilterteTermine) {
          if (this.$store.state.selectedTermine.indexOf(termin) > -1) {
            indexArray.push(this.gefilterteTermine.indexOf(termin) + 1);
          }
        }
      }
      return indexArray;
    },
    getStatus(termin: any) {
      if (termin.teilgenommen === false) {
        if (termin.angemeldet === true) {
          if (new Date(termin.datum) > new Date()) {
            return "gebucht";
          } else {
            return "nicht teilgenommen";
          }
        } else {
          if (new Date(termin.datum) > new Date()) {
            return "nicht gebucht";
          } else {
            return "nicht teilgenommen";
          }
        }
      } else {
        return "teilgenommen";
      }
    },
    document() {
      for (const termin of this.gefilterteTermine) {
        termin.status = this.getStatus(termin);
      }
      DokumentApi.getTerminliste(this.gefilterteTermine);
    },
    terminBuchen(termin: any) {
      this.removeAllFromCart();
      this.addToCart(termin);
      this.makeCart();
    },
    // Von Terminbuchung kopiert
    makeCart: function () {
      this.paymentLoading = true;
      this.payConfirmation = true;
      const url = "/cart/create/forpatient/" + this.patientId;
      axios
        .post(url, this.cart, Api.getRequestConfig())
        .then((response) => {
          const returnStr = response.data;
          switch (returnStr) {
            case "KEIN_EINZEL_GUTHABEN":
            case "KEIN_GRUPPE_GUTHABEN":
            case "KEIN_BEFUND_GUTHABEN":
            case "KEIN_ANAMNESE_GUTHABEN":
            case "KEIN_TELEFON_GUTHABEN":
              this.paymentLoading = false;
              this.payConfirmation = false;
              this.$store.state.error = {
                isVisible: true,
                headline: "Fehler bei der Buchung",
                text: "Guthaben Reicht nicht aus: " + returnStr,
              };
              break;
            default:
              this.cartId = response.data;
              this.getCart(this.cartId);
              break;
          }
        })
        .catch(() => {
          
          this.paymentLoading = false;
        });
    },
    getCart(cartId: number) {
      const url = "/cart/get/betreiber/" + cartId;
      axios
        .get(url, Api.getRequestConfig())
        .then((response) => {
          this.finalCart = response.data;
          this.payConfirmation = true;
          this.paymentLoading = false;
        })
        .catch(() => {
          
          this.paymentLoading = false;
        });
    },
    close: function () {
      this.getData();
      this.getGuthaben();
      this.payConfirmation = false;
    },
    terminDataChanged() {
      this.getData();
      this.getGuthaben();
    },
  },
};
