







































































import Api from "@/config/api";
import privilege, { Privilege } from "@/data/user/Privilege";
import { PrivilegesDto } from "@/dtos/PrivilegesDto";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";

interface Data {
  edit: boolean;
  dialogEdit: boolean;
  privileges: string[];
  active: boolean;
  allRolePrivileges: string[];
}
export default {
  props: {
    user: { type: Object, default: null },
  },
  created() {
    this.getRolePrivileges();
  },
  data(): Data {
    return {
      edit: false,
      dialogEdit: false,
      privileges: privilege.numbersToStrings(this.user.privileges),
      active: this.user.active,
      allRolePrivileges: [],
    };
  },
  computed: {
    ...mapGetters(["isMe"]),
    ...mapMutations(["setNetworkError"]),
  },
  methods: {
    getRolePrivileges() {
      const url: string = "/authority/" + this.user.role;
      axios.get<Privilege[]>(url, Api.getRequestConfig()).then(response => {
        this.allRolePrivileges = privilege.numbersToStrings(response.data);
      })
      .catch(() => {
        
      });
    },
    editSave() {
      this.user.privileges = privilege.stringsToNumbers(this.privileges);
      this.user.active = this.active;
      this.dialogEdit = false;
      this.edit = false;

      const privilegesDto: PrivilegesDto = {
        privileges: this.user.privileges,
        active: this.user.active,
      };

      const requestUrl = "/user/other/" + this.user.id + "/privileges";
      axios
        .post(requestUrl, privilegesDto, Api.getRequestConfig())
        .catch((error: Error) => {
          
          this.$store.commit("setNetworkError");
        });
    },
    editDiscard() {
      this.privileges = privilege.numbersToStrings(this.user.privileges);
      this.active = this.user.active;
      this.dialogEdit = false;
      this.edit = false;
    },
    editBack() {
      this.dialogEdit = false;
    },
  },
};
