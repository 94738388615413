







































































import api from "@/config/api";
import rules from "@/config/rules";
import router from "@/router";
import Axios from "axios";
export default {
  data: () => ({
    code: null,
    erfolgsNachricht: null,
    fehlerNachricht: null,
    passwort: "",
    passwortUeberpruefen: "",
    passwortAnzeigen: false,
    rules: rules,
    valide: false,
    erfolgsNachrichtVisible: null,
  }),
  watch: {
    erfolgsNachrichtVisible(val: null | boolean) {
      if(val != null && !val) {

        router.push({path: "/"});
      }
    }
  },
  computed: {
    passwortUeberpruefenRegel() {
      return (
        (this.passwortAnzeigen && this.passwortUeberpruefen === "") ||
        this.passwort === this.passwortUeberpruefen ||
        "Die Passwörter stimmen nicht überein."
      );
    },
  },
  methods: {
    passwortReset: function() {
      const url = "/patient/passwort/reset/" + this.$route.params.code;
      Axios.post(url, { passwort: this.passwort }, api.getRequestConfig())
        .then(() => {
          this.erfolgsNachrichtVisible = true;
          this.erfolgsNachricht =
            "Das Passwort wurde gesetzt. Sie können Sich nun mit Ihrem neuen Passwort anmelden.";
        })
        .catch(() => {
          
          this.fehlerNachricht =
            "Beim Setzen des Passworts ist ein Fehler aufgetreten!";
        });
    },
    goHome() {
      this.$router.push("/?login").catch(() => {});
    },
  },
};
