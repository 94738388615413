






















































import RezeptListeAll from "@/components/rezepte/RezeptListeAll.vue";
import RezeptListePatient from "@/components/rezepte/RezeptListePatient.vue";
import Api from "@/config/api";
import { Role } from "@/data/user/Role";
import axios from "axios";

export default {
  components: {
    RezeptListePatient,
    RezeptListeAll,
  },
  data() {
    return {
      vorname: "",
      nachname: "",
      gotName: false,
      anmerkung: "",
      patientId: undefined,
    };
  },
  created() {
    if (
      this.$route.params.patientId != null &&
      this.$route.params.patientId != undefined &&
      this.$route.params.patientId != ""
    ) {
      const numPid: number = this.$route.params.patientId;
      this.patientId = numPid;
      this.getUsername();
    }
  },
  watch: {
    $route() {
      if (
        this.$route.params.patientId != null &&
        this.$route.params.patientId != undefined &&
        this.$route.params.patientId != ""
      ) {
        try {
          const numPid: number = this.$route.params.patientId;
          this.patientId = numPid;
        } catch (err) {
          this.patientId = undefined;
        }
        this.getUsername();
      }
    },
  },
  methods: {
    getUsername() {
      axios
        .get("profil/nameonly/" + this.patientId, Api.getRequestConfig())
        .then((response) => {
          if (response != null && response.status === 200) {
            this.nachname = response.data.nachname;
            this.vorname = response.data.vorname;
            this.gotName = true;
          }
        })
        .catch((err) => {
          
          console.error("Error fetching name: " + err);
        });
    },
    isBetreiber() {
      return this.$store.getters.isRole(Role.BETREIBER);
    },
    returnToOverview() {
      this.patientId = undefined;
      this.gotName = false;
      this.$router
        .push({
          path: "/betreiber/rezepte",
        })
        .catch(() => {
          console.log("Woops: something went wrong with the router...");
        });
    },
  },
};
